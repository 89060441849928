import React from 'react';
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons/lib';

import styles from './index.module.scss';

interface Error {
  onOk?: () => void;
  title: string;
  content: string | JSX.Element;
  okText?: string;
}

export default function ModalError({
  onOk = () => undefined,
  title,
  content,
  okText = 'OK',
}: Error) {
  Modal.error({
    title,
    className: styles.modalError,
    transitionName: '',
    icon: <CloseCircleOutlined />,
    content,
    okText,
    onOk,
  });
}
