import React from 'react';
import { Col, Row, Typography } from 'antd';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import MainLogo from '../../Common/MainLogo';
import Notification from '../../Common/Notification';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import ResetPasswordForm from './Form';
import { clearSecretKey, resetPassword } from '../../../store/auth';

function ResetPassword(): JSX.Element {
  const dispatch = useAppDispatch();
  const { loading, error, secretKey } = useAppSelector((state) => state.auth);

  const handleFinish = (formData: {password: string, confirmPassword: string}) => {
    dispatch(resetPassword({ password: formData.password, secretKey })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(clearSecretKey(null));
        dispatch(signOut());
      }
    });
  };

  return (
    <Row justify="center">
      <Col>
        <div className="authFormWrapper">
          <MainLogo styles={{ marginBottom: '10px' }} />
          <Typography.Title level={4} className="title withSubTitle">
            Reset password
          </Typography.Title>
          <Typography.Paragraph className="subTitle">
            Password must be at least 8 characters long and contain character, number and special symbol.
          </Typography.Paragraph>
          <ResetPasswordForm onFinish={handleFinish} isLoading={loading} isError={!!error} />
          {
            error ? <Notification type="error" message={error.message || ''} /> : null
          }
        </div>
      </Col>
    </Row>
  );
}

export default ResetPassword;
