import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Country } from '../../types';
import { selectToken } from '../../utils';
import { RootState } from '../index';

export interface Countries {
  countries: Country[]
}

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCities: builder.query<string[], null>({
      query: () => ({
        url: '/admin/users/cities',
        method: 'GET',
      }),
    }),
    getCountries: builder.query<Countries, null>({
      query: () => ({
        url: '/common/countries',
        method: 'GET',
      }),
    }),
    uploadImage: builder.mutation<{url: string}, FormData>({
      query: (payload) => ({
        url: '/common/upload-image',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useUploadImageMutation,
} = commonApi;
