import React from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { UserActivity } from '../../../../../store/dashboard';

import styles from './index.module.scss';

interface Period extends UserActivity {
  isLast: boolean
}

export default function Period({
  period,
  days,
  value,
  isLast,
}: Period) {
  return (
    <div className={clsx(styles.period, isLast ? styles.isLast : '')}>
      <div>
        <Typography.Paragraph className={styles.days}>{days}</Typography.Paragraph>
        <Typography.Paragraph className={styles.dates}>{period}</Typography.Paragraph>
      </div>
      <div>
        <strong>{value}</strong>
      </div>
    </div>
  );
}
