import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Badge, Button, FormInstance, InputRef,
} from 'antd';
import Search from 'antd/es/input/Search';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import dayjs from 'dayjs';
import Table from '../../Common/Table';
import {
  addKeyToTableData, getSorterParams, queryFilterParams, triggerEnterKeyClick,
} from '../../../utils';
import SelectStatus from '../../Common/SelectStatus';
import {
  useDeleteUserMutation, useLazyGetUsersListQuery, Users,
} from '../../../store/users';
import SelectCountry from '../../Common/SelectCountry';
import SelectCity from '../../Common/SelectCity';
import { Status } from '../../../types';
import ModalConfirm from '../../Common/ModalConfirm';
import ModalError from '../../Common/ModalError';
import Notification from '../../Common/Notification';

import styles from './index.module.scss';
import DownloadButton from './DownloadButton';
import DownloadHorsesListButton from './DownloadHorsesListButton';

export default function UsersTable(): JSX.Element {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');
  const searchRef = useRef<InputRef>(null);

  const [isParamsFromLocalStorage, setIsParamsFromLocalStorage] = useState<boolean>(true);

  const [fetchData, { isError: getIsError }] = useLazyGetUsersListQuery();
  const [deleteUser, {
    isError: deleteIsError,
    isSuccess: deleteIsSuccess,
  }] = useDeleteUserMutation();

  useEffect(() => {
    if (isParamsFromLocalStorage) {
      const savedFilters = localStorage.getItem('filters');

      const params = savedFilters ? JSON.parse(savedFilters) : {};

      setIsParamsFromLocalStorage(false);
      setSearchParams(params);
    }
  }, []);

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const onSearch = (value: string) => {
    if (!isParamsFromLocalStorage) {
      setSearchParams({
        ...searchParams,
        ...queryFilterParams({
          search: value,
        }),
      });

      formRef.current?.submit();
    }
  };

  const toolBarRender = useCallback(() => [
    <DownloadButton key="download" />,
    <DownloadHorsesListButton key="downloadHorsesList" />,
    <Search
      key="search"
      value={searchValue}
      ref={searchRef}
      style={{ width: 278 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
  ], [searchValue]);

  useEffect(() => {
    if (!isParamsFromLocalStorage) {
      if (!searchValue.length) return onSearch('');
    }
    const searchBounce = setTimeout(() => {
      triggerEnterKeyClick(searchRef?.current);
    }, 750);

    return () => clearTimeout(searchBounce);
  }, [searchValue]);

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>,
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Users>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    localStorage.setItem('filters', JSON.stringify({
      current: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    }));

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return fetchData(params).then((res) => ({
      data: res?.data?.data ? addKeyToTableData(res.data.data) : [],
      success: res.isSuccess,
      total: res?.data?.meta.totalItems || 0,
    }));
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(params);

    return params;
  };

  const columns: ProColumns[] = [
    {
      className: styles.name,
      title: 'Name',
      dataIndex: 'name',
      hideInSearch: true,
      sorter: true,
      width: 250,
      renderText: (name) => `${name.length >= 50 ? `${name.slice(0, 50)}...` : name}`,
    },
    {
      className: styles.email,
      width: 'auto',
      title: 'Email',
      dataIndex: 'email',
      hideInSearch: true,
      sorter: false,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: false,
      renderFormItem: () => <SelectCountry />,
      renderText: (country) => country?.name,
    },
    {
      title: 'City',
      dataIndex: 'city',
      sorter: false,
      renderFormItem: () => <SelectCity />,
    },
    {
      title: 'Last activity',
      dataIndex: 'lastActivityAt',
      hideInSearch: true,
      sorter: true,
      renderText: (lastActivityAt) => (
        lastActivityAt ? dayjs(lastActivityAt).locale('en').format('DD/MM/YYYY') : null
      ),
    },
    {
      title: 'No. of logins',
      dataIndex: 'daysCount',
      hideInSearch: true,
      sorter: true,
      width: 180,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
      width: 130,
      valueEnum: Status,
      renderFormItem: () => <SelectStatus pending />,
      renderText: (status: Status) => {
        if (status === 'active') {
          return <Badge status="success" text="Active" />;
        } if (status === 'pending') {
          return <Badge status="warning" text="Pending" />;
        }

        return <Badge status="default" text="Inactive" />;
      },
    },
    {
      title: 'Registration Date',
      dataIndex: 'createdAt',
      hideInSearch: true,
      sorter: true,
      width: 200,
      renderText: (createdAt) => (
        createdAt ? dayjs(createdAt).locale('en').format('DD/MM/YYYY') : null
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      hideInSearch: true,
      width: 120,
      renderText: (_, { id, email }) => (
        <>
          <Button
            type="text"
            icon={<EditOutlined />}
            className={styles.tableBtn}
            onClick={() => {
              navigate(`/users/${id}`);
            }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            className={styles.tableBtn}
            onClick={(e) => {
              e.stopPropagation();
              ModalConfirm({
                onOk: () => handleDelete(id),
                title: 'Delete user',
                content: (
                  <>
                    <div>Are you sure you want to delete user</div>
                    <strong>{email}</strong>
                    ?
                  </>
                ),
                okText: 'Delete',
                type: 'Delete',
              });
            }}
          />
        </>
      ),
    },
  ];

  const handleDelete = (id: string) => deleteUser(id);

  useEffect(() => {
    if (deleteIsSuccess) actionRef?.current?.reload();
  }, [deleteIsSuccess]);

  useEffect(() => {
    if (getIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later',
      });
    }
  }, [getIsError]);

  useEffect(() => {
    if (deleteIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later',
      });
    }
  }, [deleteIsError]);

  return (
    <>
      { deleteIsSuccess ? <Notification type="success" message="User successfully deleted" /> : null }
      {
        !isParamsFromLocalStorage ? (
          <Table<Users>
            formRef={formRef}
            actionRef={actionRef}
            headerTitle="Users list"
            className="dataTable"
            toolBarRender={toolBarRender}
            showSorterTooltip={false}
            columns={columns}
            request={tableRequest}
            beforeSearchSubmit={beforeSearchSubmit}
            onRow={(record) => ({
              onClick: () => navigate(`/users/${record.id}`),
            })}
          />
        ) : null
      }
    </>
  );
}
