import React from 'react';
import { Divider, Typography } from 'antd';

import styles from './index.module.scss';

interface ContentTitle {
  title: string,
  divider?: boolean,
  style?: React.CSSProperties;
}

export default function ContentTitle({ title, divider, style }: ContentTitle) {
  return (
    <Typography.Title level={5} className={styles.title} style={style}>
      {
        divider ? (
          <Divider orientation="left" orientationMargin={0} className={styles.divider}>{title}</Divider>
        ) : title
    }
    </Typography.Title>
  );
}

ContentTitle.defaultProps = {
  style: {},
  divider: false,
};
