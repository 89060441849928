import { SortOrder } from 'antd/es/table/interface';
import { InputRef } from 'antd';
import { RootState } from '../store';

export function capitalizeFirstLetter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export const queryFilterParams = (queryParams: Record<string, string>): Record<string, string> => {
  const params = Object.entries(queryParams);

  const newParams: { [key: string]: string; } = {};

  params.forEach(([key, value]) => {
    if (value) {
      newParams[key] = value.toString();
    }
  });

  return newParams;
};

export const getSorterParams = (sorter: Record<string, SortOrder>): { [key: string]: string; } => {
  const sorterEntries = Object.entries(sorter);
  const newParams: { [key: string]: string; } = {};

  if (sorterEntries.length) {
    newParams.orderByColumn = sorterEntries[0][0] || '';
    newParams.orderBy = sorterEntries[0][1] === 'ascend' ? 'ASC' : 'DESC';
  }

  return newParams;
};

export function compareObjects<T extends Record<string, any>>(obj1: T, obj2: T): boolean {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj1) {
    if (obj1[key] !== undefined || obj1[key] !== '') {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}

export function addKeyToTableData<T>(arr: T[]): (T & {key: string})[] {
  return arr.map((obj) => ({ ...obj, key: crypto.randomUUID() }));
}

export const selectToken = (state: RootState) => state.auth.access;

export const clearInitialData = <T>(initialValues: Partial<T>) => (
  Object.entries(initialValues).reduce<T>((acc, [key, value]): T => {
    if (value !== null && value !== '') {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {} as T)
);

export const triggerEnterKeyClick = (refCurrent: InputRef | null) => {
  if (!refCurrent) {
    return;
  }
  const enterEvent = new KeyboardEvent('keydown', {
    key: 'Enter',
    keyCode: 13,
    bubbles: true,
    cancelable: true,
  });

  refCurrent?.input?.dispatchEvent(enterEvent);
};

export default {
  capitalizeFirstLetter,
  queryFilterParams,
  getSorterParams,
  compareObjects,
  addKeyToTableData,
  clearInitialData,
  triggerEnterKeyClick,
  selectToken,
};

export const calculateRatios = (first: number | string | undefined, second: number | string | undefined) => {
  if (first && second) {
    const firstNumber = parseFloat(first.toString().replace(',', '.'));
    const secondNumber = parseFloat(second.toString().replace(',', '.'));

    if (firstNumber === 0 || secondNumber === 0) return undefined;

    return Number((firstNumber / secondNumber).toFixed(3));
  }

  return undefined;
};
