import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../../Common/MainLogo';
import ForgotPasswordForm from './Form';
import Notification from '../../Common/Notification';
import OTPForm from './OTP';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { forgotPassword, verificationCode } from '../../../store/auth';

function ForgotPassword(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, error, secretKey } = useAppSelector((state) => state.auth);

  const [email, setEmail] = useState<string>('');
  const [otp, setOTP] = useState<string>('');
  const [attempts, setAttempts] = useState<number>(3);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setOTP(value);
  };

  const handleResendCode = () => {
    handleClearCode();
    setAttempts(3);
    dispatch(forgotPassword(email)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setIsSuccess(true);
      }
    });
  };

  useEffect(() => {
    if (isSuccess && error) {
      setAttempts((prevState) => prevState - 1);
    }
  }, [error]);

  const handleClearCode = () => {
    setOTP('');
  };

  const handleSendCode = () => {
    if (!loading) {
      dispatch(verificationCode({ secretKey, secretCode: otp })).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          navigate('/reset-password');
        }
      });
    }
  };

  useEffect(() => {
    if (otp.length === 4) {
      handleSendCode();
    }
  }, [otp]);

  const handleFinish = (data: {email: string}) => {
    setEmail(data.email);
    dispatch(forgotPassword(data.email)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setIsSuccess(true);
      }
    });
  };

  return (
    <Row justify="center">
      <Col>
        <div className="authFormWrapper">
          <MainLogo styles={{ marginBottom: '10px' }} />
          <Typography.Title level={4} className="title withSubTitle">
            {isSuccess ? 'Check your email' : 'Reset your password'}
          </Typography.Title>
          <Typography.Paragraph className="subTitle">
            {
              isSuccess ? (
                <span>
                  Verification code was sent to email
                  <strong>{email}</strong>
                </span>
              ) : (
                'Enter your email address. You will recive an email with link to reset your password.'
              )
            }
          </Typography.Paragraph>
          {
            isSuccess ? (
              <OTPForm
                handleSendCode={handleSendCode}
                handleResendCode={handleResendCode}
                handleChange={handleChange}
                handleClearCode={handleClearCode}
                values={otp}
                isLoading={loading}
                isError={!!error}
                attempts={attempts}
              />
            ) : (
              <ForgotPasswordForm onFinish={handleFinish} isLoading={loading} isError={!!error} />
            )
          }
          {
            error ? <Notification type="error" message={error.message || ''} /> : null
          }
        </div>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
