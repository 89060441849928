import React, { useRef, useState, useEffect } from 'react';
import { FormInstance } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate, useParams } from 'react-router-dom';
import DetailPageToolBar from '../../../Common/DetailPageToolBar';
import ContentTitle from '../../../Common/ContentTitle';
import {
  useDeleteUserMutation,
  useEditUserMutation, useGetUserMutation, UserDetail,
} from '../../../../store/users';
import { updateUnsavedChanges } from '../../../../store/unsavedChanges';
import { useAppDispatch } from '../../../../hooks/redux';
import ModalError from '../../../Common/ModalError';
import Loading from '../../../Common/Loading';
import Notification from '../../../Common/Notification';
import UploadImage from '../../../Common/UploadImage';
import UserDetailForm from '../Form';

export default function UserEdit() {
  const formRef = useRef<FormInstance>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [getUser,
    {
      data: getData,
      isLoading: getIsLoading,
      isError: getIsError,
      isSuccess: getIsSuccess,
    },
  ] = useGetUserMutation();

  const [deleteUser,
    {
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      isSuccess: deleteIsSuccess,
    },
  ] = useDeleteUserMutation();

  const [updateUser,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
    },
  ] = useEditUserMutation();

  useEffect(() => {
    if (id) getUser(id);
  }, [id]);

  const handleSave = () => {
    formRef.current?.submit();
  };

  const handleDelete = () => {
    if (id) deleteUser(id);
  };

  const handleFinish = (formData: UserDetail) => {
    let {
      // eslint-disable-next-line prefer-const
      email, city, address, ...newFormData
    } = formData;

    if (photoUrl) {
      newFormData = { ...newFormData, photoUrl };
    }
    if (id) {
      updateUser({ formData: newFormData, id });
      dispatch(updateUnsavedChanges(false));
    }
  };

  useEffect(() => {
    if (deleteIsSuccess) navigate(-1);
  }, [deleteIsSuccess]);

  const handleImage = (value: string) => {
    setPhotoUrl(value);
    dispatch(updateUnsavedChanges(true));
  };

  useEffect(() => {
    if (deleteIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  }, [deleteIsError]);

  useEffect(() => {
    if (getIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  }, [getIsError]);

  useEffect(() => {
    if (updateIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  }, [updateIsError]);

  return (
    <>
      <Loading visible={deleteIsLoading || getIsLoading || updateIsLoading} />
      {
        updateIsSuccess ? <Notification type="success" message="Changes successfully saved" /> : null
      }
      <DetailPageToolBar
        title="Edit user"
        handleSave={handleSave}
        handleDelete={handleDelete}
        deleteText="user"
        deleteTitleText="user"
      />
      <Content className="content-wrap">
        <ContentTitle title="Edit user" style={{ margin: '0 0 16px' }} />
        <UploadImage handleImage={handleImage} className="uploadUser" type="avatar" imageUrl={getData?.photoUrl} />
        {
          getIsSuccess && getData ? (
            <UserDetailForm
              handleFinish={handleFinish}
              formRef={formRef}
              initialValues={getData}
            />
          ) : null
        }
      </Content>
    </>
  );
}
