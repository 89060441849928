import React, { useState } from 'react';
import { Button, Form as AntdForm, Input } from 'antd';
import { Action } from '@triare/auth-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { createRulesForAntd, validationSignInEmailAndPassword } from '../../../../utils/validations';

import styles from './index.module.scss';
import { validateTrigger } from '../../../../types';

interface FormProps<P> {
  handleSignIn: (payload: P) => Action | void;
  isLoading: boolean
}

const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

function SignInForm<P>({ handleSignIn, isLoading }: FormProps<P>): JSX.Element {
  const [form] = AntdForm.useForm();
  const [isFinishFailed, setIsFinishFailed] = useState<boolean>(false);
  const [validationType, setValidationType] = useState({
    email: { validateTrigger: validateTrigger.onBlur },
    password: { validateTrigger: validateTrigger.onBlur },
  });

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValidationType({
      ...validationType,
      [e.target.name]: { validateTrigger: validateTrigger.onChange },
    });
  };

  const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore ignore this line
    if (isFinishFailed && validationType[e.target.name].validateTrigger === validateTrigger.onBlur) {
      form.setFields([{ name: e.target.name, errors: [] }]);
    }
  };

  return (
    <AntdForm
      form={form}
      className="authForm"
      name="signIn"
      autoComplete="off"
      layout="vertical"
      onFinish={handleSignIn}
      onFinishFailed={() => setIsFinishFailed(true)}
    >
      <AntdForm.Item
        name="email"
        className="item"
        rules={[validationRules]}
        validateTrigger={validationType.email.validateTrigger}
      >
        <Input
          placeholder="Email"
          name="email"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </AntdForm.Item>

      <AntdForm.Item
        name="password"
        rules={[validationRules]}
        validateTrigger={validationType.password.validateTrigger}
      >
        <Input.Password
          name="password"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          autoComplete="password"
          placeholder="Password (8+ characters)"
        />
      </AntdForm.Item>
      <AntdForm.Item className="forgotPass">
        <NavLink to="/forgot-password">
          Forgot password?
        </NavLink>
      </AntdForm.Item>

      <AntdForm.Item
        className={clsx('submit', styles.signInBtn)}
      >
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={isLoading}>
          Log in
        </Button>
      </AntdForm.Item>
    </AntdForm>

  );
}
export default SignInForm;
