import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { selectToken } from '../../utils';
import { RootState } from '../index';

export interface Message {
  lang: string,
  titleEn?: string,
  titleNo?: string,
  messageEn?: string,
  messageNo?: string,
  forTeamOnly: boolean,
  [key: string]: any,
}

export const createNotificationApi = createApi({
  reducerPath: 'createNotificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createNotification: builder.mutation<string, Message>({
      query: (formData) => ({
        url: `/admin/users/send-push-to-all?lang=${formData.lang}`,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const {
  useCreateNotificationMutation,
} = createNotificationApi;
