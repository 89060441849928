import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib/select';
import { Option } from '../../../types';

export interface ITimePeriod {
  today: string,
  yesterday: string,
  thisWeek: string,
  lastWeek: string,
  last7Days: string,
  last28Days: string,
  last30Days: string,
  last90Days: string,
  last12Months: string,
  lastCalendarYear: string,
  thisYear: string,
  custom?: string,
}

export const TimePeriod: ITimePeriod = {
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This week',
  lastWeek: 'Last week',
  last7Days: 'Last 7 days',
  last28Days: 'Last 28 days',
  last30Days: 'Last 30 days',
  last90Days: 'Last 90 days',
  last12Months: 'Last 12 months',
  lastCalendarYear: 'Last calendar year',
  thisYear: 'This year (Jan - Today)',
  custom: 'Custom',
};

export default function SelectTimePeriod({ ...props }: SelectProps<string, Option>): JSX.Element {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (TimePeriod) {
      setOptions(
        Object.entries(TimePeriod)
          .map(([key, value]): Option => ({ label: value, value: key })),
      );
    }
  }, []);

  return (
    <Select options={options} placeholder="Please select" style={{ width: 200 }} {...props} />
  );
}
