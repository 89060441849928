import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { productsApi } from './nutrition';
import { usersApi } from './users';
import unsavedChanges from './unsavedChanges';
import { reducer as authReducer, saga as authSaga } from './auth';
import { commonApi } from './common';
import { dashboardApi } from './dashboard';
import { createNotificationApi } from './createNotification';

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [createNotificationApi.reducerPath]: createNotificationApi.reducer,
    unsavedChanges,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    sagaMiddleware,
    productsApi.middleware,
    usersApi.middleware,
    commonApi.middleware,
    dashboardApi.middleware,
    createNotificationApi.middleware,
  ]),
  devTools: process.env.REACT_APP_ENV !== 'production',
});

sagaMiddleware.run(function* runSaga() {
  yield spawn(authSaga, store);
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
