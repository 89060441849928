export enum FeedType {
  forage = 'forage',
  grain = 'grain',
  supplement = 'supplement',
}

export enum Status {
  active = 'active',
  inactive = 'inactive',
  pending = 'pending',
}

export enum Role {
  user = 'user',
  admin = 'admin'
}

export enum validateTrigger {
  onBlur= 'onBlur',
  onChange= 'onChange',
}

export enum GenderType {
  male = 'male',
  female = 'female',
  'prefer-not-to-say' = 'Prefer not to say'
}

export interface Option {
  value: string | boolean | number;
  label: string | JSX.Element;
  disabled?: boolean;
}

export interface PagingDataResponse<T> {
  data: T[]
  meta: {
    totalItems: number,
    itemsPerPage: number,
    totalPages: number,
    currentPage: number,
  }
}

export interface Horses {
  id: string
  name: string
  imageUrl: string
  dateOfBirth: string
  weight: Weight
  gender: Gender
  breed: Breed
  discipline: Discipline
  activityLevel: ActivityLevel
  createdAt: string
  updatedAt: string
}

export interface Weight {
  id: string
  onDate: string
  value: number
  neckSize: number
  girthSize: number
  bodyLength: number
  height: number
  createdAt: string
  updatedAt: string
}

export interface Gender {
  value: string,
  label: string
}

export interface Breed {
  id: string
  name: string
}

export interface Discipline {
  id: string
  name: string
}

export interface ActivityLevel {
  id: string
  name: string
}

export interface Country {
  id: string
  name: string
}

export type RequiredKeys<T> = { [K in keyof T]-?: T extends { [K1 in K]: any } ? K : never}[keyof T]

export interface ErrorData {
  statusCode: number
  message: string[]
  error: string
}

export interface ResponseError {
  status: number
  data: ErrorData
}
