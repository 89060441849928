import * as yup from 'yup';
import { Rule, RuleObject } from 'rc-field-form/lib/interface';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const name: yup.StringSchema = yup.string()
  .max(100, 'Cannot be more than 100 characters.')
  .required('This field is required');

export const username: yup.StringSchema = yup.string()
  .max(50, 'Cannot be more than 50 characters.')
  .required('This field is required');

export const password: yup.StringSchema = yup.string()
  .min(8, 'Password must be at least 8 characters')
  .max(100, 'Maximum 100 characters')
  .matches(/^[^\s].*[^\s]$/, 'Password should not start or end with space')
  .matches(/^(?=.*[0-9])(?=.*[\D])([\D0-9]+)$/, 'Must contain numbers and letters')
  .matches(/[^A-Za-z0-9]|[A-Z]/, 'Must contain one uppercase letter or special symbol')
  .matches(/[a-z]/, 'Must contain one lowercase letter')
  .required('This field is required');

export const confirmPassword: yup.StringSchema = yup.string()
  .oneOf([yup.ref('password'), ''], 'Passwords must match')
  .required('This field is required');

export const phone: yup.StringSchema = yup.string()
  .max(15, 'Please enter a valid phone number.')
  .matches(phoneRegExp, 'Phone number is not valid')
  .required('This field is required');

export const email: yup.StringSchema = yup.string()
  .email('Invalid email')
  .test('starts-with-number', 'Invalid email', (value) => {
    const regex = /^[0-9]/;

    return !regex.test(value || '');
  })
  .test('no-consecutive-dots', 'Invalid email', (value) => !/\.\.+/g.test(value || ''))
  .matches(/^[^.].*[^.]@[a-z0-9.-]+\.[a-z]{2,}$/, 'Invalid email')
  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email')
  .required('This field is required');

export const confirmEmail: yup.StringSchema = yup.string()
  .oneOf([yup.ref('email')], 'Email must match')
  .required('This field is required');

export const zipCode: yup.StringSchema = yup.string()
  .min(5, 'Please enter a valid Zip Code.')
  .max(10, 'Please enter a valid Zip Code.')
  .matches(/^\d{5}$|^\d{5}-\d{4}$/, 'Please enter a valid Zip Code.')
  .required('This field is required');

export const validationSignUpEmailOnly = yup.object().shape({
  email,
});

export const validationSignUpEmailAndPassword = yup.object().shape({
  password,
  email,
});

export const validationSignUpPhoneOnly = yup.object().shape({
  phone,
});

export const validationSignUp = yup.object().shape({
  password,
  confirmPassword,
  email,
  phone,
  firstName: name,
  lastName: name,
});

export const validationChangePassword = yup.object().shape({
  currentPassword: password,
  password,
  confirmPassword,
});

export const validationNewPassword = yup.object().shape({
  password,
  confirmPassword,
});

export const validationChangeEmail = yup.object().shape({
  email,
  confirmEmail,
});

export const validationSignInEmailAndPassword = yup.object().shape({
  email,
  password,
});

export const validationSignInUsernameAndPassword = yup.object().shape({
  username,
  password,
});

export const validationSignInPhoneAndPassword = yup.object().shape({
  phone,
  password,
});

export const validationForgotPassword = yup.object().shape({
  email,
});

const { shape } = yup.object();

export const createRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async validator({ field }: RuleObject, value) {
    await yupRule.validateSyncAt(field, { [field]: value });
  },
});

const validatorRules: { [key: string]: Rule } = {};

export const getRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (validatorRules[yupRule.name]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return validatorRules[yupRule.name];
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  validatorRules[yupRule.name] = createRulesForAntd(yupRule);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return validatorRules[yupRule.name];
};

export default {
  validationSignUp,
  validationChangePassword,
  validationNewPassword,
  validationChangeEmail,
  validationSignIn: validationSignInEmailAndPassword,
  validationForgotPassword,
  password,
  confirmPassword,
  email,
  confirmEmail,
};
