import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';

export type PrivateRouteProps = {
  children: JSX.Element;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const { authorized, user, secretKey } = useAppSelector((state) => state.auth);
  const location = useLocation();

  if ((authorized && user?.role === 'admin') || (secretKey && authorized)) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}
