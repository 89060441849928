import React from 'react';
import Layout from '../../components/Layout';
import config from '../../store/auth';
import Content from '../../components/Common/Wrapper/Content';
import PageHeader from '../../components/Common/PageHeader';
import CreateNotification from '../../components/Pages/CreateNotification';

const { displayName } = config;

export default function CreateNotificationPage(): JSX.Element {
  document.title = `${displayName}: Users`;

  return (
    <Layout>
      <Content>
        <PageHeader title="Create notification" />
        <CreateNotification />
      </Content>
    </Layout>
  );
}
