import { createSlice } from '@reduxjs/toolkit';

export interface initialState {
  unsavedChanges: boolean
}

export const unsavedChangesInitialState: initialState = {
  unsavedChanges: false,
};

const unsavedChanges = createSlice({
  name: 'unsavedChanges',
  initialState: unsavedChangesInitialState,
  reducers: {
    updateUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
  },
});

export const { updateUnsavedChanges } = unsavedChanges.actions;
export default unsavedChanges.reducer;
