import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Country, PagingDataResponse, Status } from '../../types';
import { selectToken } from '../../utils';
import { RootState } from '../index';

export interface UserDetail {
  id: string,
  name?: string,
  email?: string,
  status?: string,
  lastActivityAt?: string,
  gender?: string,
  horses?: HorseAndProduct[],
  city?: string,
  address?: string,
  country?: Country,
  photoUrl?: string,
  dateOfBirth?: string,
  createdAt?: string,
  updatedAt?: string,
  calculatorUsedAt?: string,
  daysCount?: number,
  notesNumber?: number,
  workoutsNumber?: number,
  workoutLogsNumber?: number,
  noteLogsNumber?: number
}

export interface Users {
  id: string,
  name: string,
  email: string,
  status: Status,
  lastActivityAt: string,
  gender: string,
  horses: HorseAndProduct[],
  city: string,
  address: string,
  country: Country,
  createdAt: string,
  updatedAt: string,
  daysCount: number,

}

export type HorseAndProduct = {
  id?: string,
  name?: string,
  products?: UserProducts[],
}

export type UserProducts = {
  id?: string,
  nameEn?: string,
  nameNo?: string,
}

export interface UserGetParams {
  country?: string[],
  city?: string[],
  status?: string[],
  nutritionType?: string,
  users?: string[],
  page?: number[],
  limit?: string[],
  search?: string[],
  orderBy?: string[],
  orderByColumn?: string[],
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUsersList: builder.query<PagingDataResponse<Users>, UserGetParams>({
      query: (params) => ({
        url: '/admin/users',
        method: 'GET',
        params,
      }),
    }),
    getUser: builder.mutation<UserDetail, string>({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: 'GET',
      }),
    }),
    editUser: builder.mutation<string, { formData: UserDetail, id: string}>({
      query: ({ formData, id }) => ({
        url: `/admin/users/${id}`,
        method: 'PATCH',
        body: formData,
      }),
    }),
    deleteUser: builder.mutation<string, string>({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetUsersListQuery,
  useEditUserMutation,
  useGetUserMutation,
  useDeleteUserMutation,
} = usersApi;
