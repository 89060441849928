import { ReactNode } from 'react';
import {
  Route, RouteObject, RouterProvider, createBrowserRouter, Navigate,
} from 'react-router-dom';

import { routes as menuRoutes } from '../components/Layout/Menu/index';
import PrivateRoute from './PrivateRoute';
import SignInPage from '../pages/SignIn';
import ForgotPasswordPage from '../pages/ForgotPassword';
import ResetPasswordPage from '../pages/ResetPassword';
import NutritionCreatePage from '../pages/Nutrition/Create';
import NutritionEditPage from '../pages/Nutrition/Edit';
import UserEditPage from '../pages/Users/Edit';

export interface Route {
  route?: RouteObject;
  name?: string;
  icon?: ReactNode;
  parent?: Route;
  hidden?: boolean;
  onClick?: () => void;
  children?: Route[];

  // eslint-disable-next-line
  [key: string]: any;
}

export function addChildrenRoutes(routes: Route[]): Route[] {
  const listOfNoParent: Route[] = [];

  const recursiveUpdate = (item: Route, parent?: Route): Route => {
    if (parent) {
      // eslint-disable-next-line no-param-reassign
      item.parent = parent;

      if (parent?.route?.children && item.route) {
        parent.route.children.push(item.route);
      }
    }

    if (item.route) {
      if (item && item.children) {
        // eslint-disable-next-line no-param-reassign
        item.route.children = [];

        item.children.forEach((route) => recursiveUpdate(route, item));
      }
    } else if (item.children) {
      item.children.filter(({ route }) => route).forEach((child) => listOfNoParent.push(recursiveUpdate(child)));
    }

    return item;
  };

  return [
    ...routes.map((route) => recursiveUpdate(route)),
    ...listOfNoParent,
  ].filter(({ route }) => route);
}

export const routes: Route[] = addChildrenRoutes(menuRoutes);

const router = createBrowserRouter(
  [
    ...routes.map(({ route }) => route as RouteObject),
    {
      path: '/users/:id',
      element: <UserEditPage />,
    },
    {
      path: '/nutrition/create',
      element: <PrivateRoute><NutritionCreatePage /></PrivateRoute>,
    },
    {
      path: '/nutrition/:id',
      element: <PrivateRoute><NutritionEditPage /></PrivateRoute>,
    },
    {
      path: '/login',
      element: <SignInPage />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: '/reset-password',
      element: <PrivateRoute><ResetPasswordPage /></PrivateRoute>,
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" replace />,
    },
  ],
);

export default function Routes(): JSX.Element {
  return (
    <RouterProvider router={router} />
  );
}
