import React from 'react';
import { Provider } from 'react-redux';
import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import store from './store';
import Routes from './routes';
import config from './store/auth';

const { displayName } = config;

export default function App(): JSX.Element {
  document.title = displayName;

  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          colorPrimary: '#F3A482',
          colorPrimaryHover: '#FFCAB0',
          fontFamily: "'Open Sans', sans-serif",
        },
      }}
    >
      <Provider store={store}>
        <Routes />
      </Provider>
    </ConfigProvider>
  );
}
