import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib/select';
import { Option } from '../../../types';
import { useGetCountriesQuery } from '../../../store/common';

export default function SelectCountry({ ...props }: SelectProps<string, Option>): JSX.Element {
  const [options, setOptions] = useState<Option[]>([]);

  const { data, isSuccess } = useGetCountriesQuery(null);

  useEffect(() => {
    if (data) {
      setOptions(
        data.countries.map((country): Option => ({ label: country?.name, value: country?.id })),
      );
    }
  }, [isSuccess]);

  const filterOption = (input: string, option: Option | undefined): boolean => (
    !!option?.label.toString().toLowerCase().includes(input.toLowerCase())
  );

  return (
    <Select
      showSearch
      options={options}
      placeholder="Country"
      allowClear
      filterOption={filterOption}
      {...props}
    />
  );
}
