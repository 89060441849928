import React, { useEffect, useRef, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { FormInstance } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../Common/Loading';
import DetailPageToolBar from '../../../Common/DetailPageToolBar';
import ContentTitle from '../../../Common/ContentTitle';
import ImageUploader from '../../../Common/UploadImage';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { updateUnsavedChanges } from '../../../../store/unsavedChanges';
import {
  Product, useEditProductMutation,
  useDeleteProductMutation,
  useGetProductMutation, ProductType, useLazyGetProductUsersListQuery, ProductUsers,
} from '../../../../store/nutrition';
import NutritionDetailForm from '../Form/inde';
import ModalError from '../../../Common/ModalError';
import Notification from '../../../Common/Notification';
import ProductUsersTable from '../ProductUsersTable';
import { Status } from '../../../../types';

const keysToIncludes: Array<keyof Product> = [
  'energy',
  'crudeProtein',
  'lysine',
  'calcium',
  'phosphorus',
  'magnesium',
  'sodium',
  'potassium',
  'sulfur',
  'iron',
  'copper',
  'manganese',
  'zinc',
  'selenium',
  'cobalt',
  'iodine',
  'vitaminA',
  'vitaminD',
  'vitaminE',
  'vitaminB1',
  'vitaminB2',
  'vitaminB6',
  'vitaminB12',
  'niacin',
  'folicAcid',
  'biotin',
  'vitaminC',
  'sugar',
  'starch',
  'fiber',
  'fat',
];

export const calculatedValuesByDM = (formData: Product, isFinish?: boolean, isStart?: boolean) => {
  if (!formData?.dryMatter) {
    return formData;
  }

  return Object.entries(formData)
    .reduce((obj: Product, [key, value]) => {
      const typedKey = key as keyof Product;

      if (keysToIncludes.includes(typedKey) && value && formData.dryMatter) {
        const strValue = value?.toString() || '';

        const parsedValue = strValue.includes(',')
          ? parseFloat(strValue.replace(',', '.'))
          : parseFloat(strValue);

        if ((formData.nutrientsType === 'feed' && !isStart) || isFinish) {
          // @ts-ignore ignore line below
          // eslint-disable-next-line no-param-reassign
          obj[typedKey] = +(parsedValue * (formData.dryMatter / 100)).toFixed(4);
        }

        if (formData.nutrientsType === 'dryMatter' && !isFinish) {
          // @ts-ignore ignore line below
          // eslint-disable-next-line no-param-reassign
          obj[typedKey] = +(parsedValue / (formData.dryMatter / 100)).toFixed(4);
        }
      }

      return { ...formData, ...obj };
    }, {} as Product);
};

export default function NutritionEdit() {
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState<string>('');
  const formRef = useRef<FormInstance>(null);
  const [getProduct,
    {
      data: getData,
      isLoading: getIsLoading,
      isError: getIsError,
      isSuccess: getIsSuccess,
    },
  ] = useGetProductMutation();

  const [deleteProduct,
    {
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      isSuccess: deleteIsSuccess,
      error: deleteError,
    },
  ] = useDeleteProductMutation();
  const [updateProduct,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      error: updateError,
    },
  ] = useEditProductMutation();

  useEffect(() => {
    if (id) getProduct(id);
  }, [id]);

  const handleSave = () => {
    formRef.current?.submit();
  };

  const handleDelete = () => {
    if (id) deleteProduct(id);
  };

  useEffect(() => {
    if (deleteIsSuccess) navigate(-1);
  }, [deleteIsSuccess]);

  const handleImage = (value: string) => {
    setImageUrl(value);
    dispatch(updateUnsavedChanges(true));
  };

  const handleFinish = async (formData: Product) => {
    // eslint-disable-next-line prefer-const
    let { productType, ...newFormData } = formData;

    if (formData.productType === ProductType.community && user) {
      newFormData = { ...newFormData, user: { id: user.id, email: user.email } };
    } else {
      newFormData = { ...newFormData, user: null };
    }

    if (imageUrl) {
      newFormData = { ...newFormData, imageUrl };
    }

    if (id) {
      await updateProduct({ formData: newFormData, id });
      dispatch(updateUnsavedChanges(false));

      if (newFormData.nutrientsType === 'dryMatter') {
        await getProduct(id);
      }
    }
  };

  useEffect(() => {
    if (deleteIsError) {
      ModalError({
        title: 'Error',
        content: `${deleteError}`,
      });
    }
  }, [deleteIsError]);

  useEffect(() => {
    if (getIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  }, [getIsError]);

  useEffect(() => {
    if (updateIsError) {
      ModalError({
        title: 'Error',
        content: updateError as string,
      });
    }
  }, [updateIsError]);

  const [fetchData, { data: productUsers }] = useLazyGetProductUsersListQuery();

  useEffect(() => {
    handleGetProductUsers();
  }, []);

  const [hideDisableBtn, setHideDisableBtn] = useState<boolean>(true);
  const [users, setUsers] = useState<ProductUsers[]>([]);
  const handleGetProductUsers = async () => {
    await fetchData({ productId: id });
  };

  useEffect(() => {
    if (productUsers?.data) {
      setUsers(productUsers.data);
      setHideDisableBtn(false);
    }
  }, [productUsers]);

  return (
    <>
      <Loading visible={deleteIsLoading || getIsLoading || updateIsLoading} />
      {
        updateIsSuccess ? <Notification type="success" message="Changes successfully saved" /> : null
      }
      <DetailPageToolBar
        title="Edit nutrition product"
        handleSave={handleSave}
        handleDelete={handleDelete}
        isDisabled={(getData?.status === Status.inactive && users.length > 0) || hideDisableBtn || getIsLoading}
        path={`/nutrition?productType=${getData?.user !== null ? 'community' : 'system'}`}
        deleteText="product"
        deleteTitleText="product"
      />
      <Content className="content-wrap">
        <ContentTitle title="Edit nutrition product" style={{ padding: '0 0 16px' }} />
        <ImageUploader handleImage={handleImage} imageUrl={getData?.imageUrl} />
        <ContentTitle title="General product information" divider />
        {
          getIsSuccess && getData ? (
            <NutritionDetailForm
              handleFinish={handleFinish}
              formRef={formRef}
              initialValues={getData}
            />
          ) : null
        }
        <ProductUsersTable productId={id || ''} />
      </Content>
    </>
  );
}
