import React from 'react';
import { Empty } from 'antd';
import styles from './index.module.scss';

interface EmptyPlaceholder {
  text?: string
}

function EmptyPlaceholder({ text }: EmptyPlaceholder) {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.empty} description={text} />
  );
}

EmptyPlaceholder.defaultProps = {
  text: 'No data',
};

export default EmptyPlaceholder;
