import React, { useEffect, useRef, useState } from 'react';
import {
  Col, Form as AntdForm, FormInstance, Input, Row, Checkbox, Button, message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Content } from 'antd/es/layout/layout';
import { Message, useCreateNotificationMutation } from '../../../store/createNotification';
import ModalConfirm from '../../Common/ModalConfirm';

export default function CreateNotification(): JSX.Element {
  const formRef = useRef<FormInstance>(null);
  const [updateMessage] = useCreateNotificationMutation();
  const [isEnFormFilled, setIsEnFormFilled] = useState<boolean>(false);
  const [isNoFormFilled, setIsNoFormFilled] = useState<boolean>(false);
  const fieldsToSend = ['titleEn', 'messageEn', 'titleNo', 'messageNo'];
  const handleSend = async (language: string) => {
    const valuesToSubmit: Message = {
      lang: '',
      forTeamOnly: false,
    };

    valuesToSubmit.lang = language;

    fieldsToSend.forEach((field) => {
      const fieldValue = formRef.current?.getFieldValue(field) || '';

      valuesToSubmit[field] = fieldValue;
    });
    valuesToSubmit.forTeamOnly = formRef.current?.getFieldValue('internalTestersOnly') || false;
    try {
      await updateMessage(valuesToSubmit);
      message.success('Message sent');
      if (language === 'en') {
        setIsEnFormFilled(false);
        formRef.current?.resetFields(['titleEn', 'messageEn']);
      } else {
        setIsNoFormFilled(false);
        formRef.current?.resetFields(['titleNo', 'messageNo']);
      }
    } catch (error) {
      message.error('Error');
    }
  };
  const handleFieldChange = () => {
    const isEnFilled = fieldsToSend.slice(0, 2).every((field) => {
      const fieldValue = formRef.current?.getFieldValue(field) || '';

      return fieldValue.trim() !== '';
    });
    const isNoFilled = fieldsToSend.slice(2).every((field) => {
      const fieldValue = formRef.current?.getFieldValue(field) || '';

      return fieldValue.trim() !== '';
    });

    setIsEnFormFilled(isEnFilled);
    setIsNoFormFilled(isNoFilled);
  };

  useEffect(() => {
    handleFieldChange();
  }, []);

  return (
    <Content className="content-wrap">
      <AntdForm
        ref={formRef}
        onFieldsChange={handleFieldChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <AntdForm.Item
              label=""
              name="titleEn"
            >
              <Input placeholder="Title of push-message (EN)" minLength={1} maxLength={100} />
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <AntdForm.Item
              label=""
              name="titleNo"
            >
              <Input placeholder="Title of push-message (NO)" minLength={1} maxLength={100} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <AntdForm.Item
              label=""
              name="messageEn"
            >
              <TextArea
                rows={8}
                autoSize={{ minRows: 8, maxRows: 8 }}
                placeholder="Text body (EN)"
                minLength={1}
                maxLength={255}
              />
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <AntdForm.Item
              label=""
              name="messageNo"
            >
              <TextArea
                rows={8}
                autoSize={{ minRows: 8, maxRows: 8 }}
                placeholder="Text body (NO)"
                minLength={1}
                maxLength={255}
              />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => {
                ModalConfirm({
                  title: 'Confirm',
                  content: 'Are you sure you want to send a message?',
                  okText: 'Yes',
                  type: 'Confirm',
                  onOk: () => {
                    handleSend('en');
                  },
                });
              }}
              disabled={!isEnFormFilled}
            >
              Send (EN)
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => {
                ModalConfirm({
                  title: 'Confirm',
                  content: 'Are you sure you want to send a message?',
                  okText: 'Yes',
                  type: 'Confirm',
                  onOk: () => {
                    handleSend('no');
                  },
                });
              }}
              disabled={!isNoFormFilled}
            >
              Send (NO)
            </Button>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <AntdForm.Item
              name="internalTestersOnly"
              valuePropName="checked"
              style={{ marginTop: '12px' }}
            >
              <Checkbox>Internal testers only</Checkbox>
            </AntdForm.Item>
          </Col>
        </Row>
      </AntdForm>
    </Content>
  );
}
