import React from 'react';

import styles from './index.module.scss';

export interface ContentProps {
  children: React.ReactNode
}

function Content({ children }: ContentProps): JSX.Element {
  return (
    <div className={styles.content}>
      {children}
    </div>
  );
}

export default Content;
