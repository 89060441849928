import React from 'react';
import config from '../../store/auth';
import Content from '../../components/Common/Wrapper/Content';
import AuthLayout from '../../components/Layout/Auth';
import ResetPassword from '../../components/Pages/ResetPassword';

const { displayName } = config;

export default function ResetPasswordPage(): JSX.Element {
  document.title = `${displayName}: Reset Password`;

  return (
    <AuthLayout>
      <Content>
        <ResetPassword />
      </Content>
    </AuthLayout>
  );
}
