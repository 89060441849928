import ProTable, { ProTableProps } from '@ant-design/pro-table';
import React, { useRef } from 'react';
import { Button } from 'antd';
import { ParamsType } from '@ant-design/pro-provider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import useCurrentLocation from '../../../hooks/router';

import styles from './index.module.scss';

const handleFilterCollapseButton = (collapsed: boolean) => {
  const arrow = collapsed ? <DownOutlined /> : <UpOutlined />;

  return (
    <span className={styles.filterCollapse}>
      {collapsed ? 'More' : 'Less'}
      {arrow}
    </span>
  );
};

export default function Table<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text'
>(props: ProTableProps<DataType, Params, ValueType> & {syncToUrl?: boolean}): JSX.Element {
  const key = useRef<number>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentLocation = useCurrentLocation();

  const { pagination, search, syncToUrl } = props;

  const newProps = {
    dateFormatter: 'string',
    ...props,
    key: key.current,
  } as ProTableProps<DataType, Params, ValueType>;

  newProps.options = {
    density: false,
    ...newProps.options,
  };

  newProps.form = search || !syncToUrl ? undefined : {
    syncToUrl: true,
    extraUrlParams: { search: searchParams.get('search') },
    collapseRender: (collapsed) => handleFilterCollapseButton(collapsed),
    ...newProps.form,
  };

  newProps.pagination = pagination ?? {
    defaultPageSize: 10,
    size: 'default',
    ...newProps.pagination,
  };

  newProps.search = search ?? {
    optionRender: ({ form }) => ([
      <Button
        key="Clear"
        onClick={(e) => {
          e.preventDefault();
          setTimeout(() => {
            key.current = Date.now();
            navigate(currentLocation, { replace: true });
          }, 0);
        }}
      >
        Reset
      </Button>,
      <Button
        key="Query"
        type="primary"
        onClick={(e) => {
          e.preventDefault();
          form?.submit();
        }}
      >
        Filter
      </Button>,
    ]),
    ...newProps.search,
  };

  return (
    <ProTable<DataType, Params, ValueType>
      {...newProps}
    />
  );
}

Table.defaultProps = {
  syncToUrl: true,
};
