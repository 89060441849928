import React, { useState } from 'react';
import { Button } from 'antd';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import Notification from '../../../Common/Notification';
import { useAppSelector } from '../../../../hooks/redux';

const exportData = (data: Blob, fileName: string, type: string) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export default function DownloadButton() {
  const { access } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const handleClick = async () => {
    const params = Object.fromEntries(searchParams);

    delete params.current;
    delete params.pageSize;
    setIsLoading(true);

    const res = await axios.get(`${process.env.REACT_APP_API}admin/users/export`, {
      params,
      headers: {
        Authorization: `Bearer ${access?.token}`,
      },
    });

    setIsLoading(false);
    if (res.status === 200) {
      exportData(
        res.data,
        `users_${dayjs().format('YYYY_MM_DD_hh_mm_ss')}.csv`,
        'text/csv; charset=utf-8',
      );

      return;
    }

    setIsError(true);
  };

  return (
    <div>
      {isError ? <Notification type="error" message="Something went wrong!" /> : null}
      <Button
        onClick={handleClick}
        type="primary"
        loading={isLoading}
      >
        Download users list .csv file
      </Button>
    </div>
  );
}
