import React, { useEffect, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ModalConfirm from '../ModalConfirm';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { updateUnsavedChanges } from '../../../store/unsavedChanges';

import styles from './index.module.scss';

interface DetailPageToolBar {
  title: string,
  deleteText: string,
  deleteTitleText: string,
  handleSave: () => void,
  handleDelete?: () => void,
  isDisabled?: boolean
  path?: string,
}

function DetailPageToolBar({
  title,
  handleSave,
  handleDelete,
  path,
  deleteText,
  deleteTitleText,
  isDisabled,
}: DetailPageToolBar) {
  const { unsavedChanges } = useAppSelector((state) => state.unsavedChanges);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [confirm, setConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (confirm) {
      // @ts-ignore ignore line below
      navigate(path || -1);
    }
  }, [confirm]);

  return (
    <div className={styles.toolBarWrap}>
      <Space size={16}>
        <Button
          icon={<ArrowLeftOutlined />}
          type="default"
          onClick={() => {
            if (unsavedChanges) {
              ModalConfirm({
                title: 'Unsaved changes',
                content: 'You have unsaved changes. Discard changes and leave?',
                okText: 'Discard',
                type: 'Discard',
                onOk: () => {
                  dispatch(updateUnsavedChanges(false));
                  setConfirm(true);
                },
              });
            } else {
              setConfirm(true);
            }
          }}
        >
          Back
        </Button>
        <Typography.Title level={4} className={styles.title}>{title}</Typography.Title>
      </Space>
      <Space className={styles.actionsWrap}>
        {
          handleDelete ? (
            <Button
              type="primary"
              disabled={isDisabled}
              className={clsx(styles.deleteBtn, isDisabled ? styles.disabledBtn : '')}
              onClick={() => {
                ModalConfirm({
                  title: `Delete ${deleteTitleText}`,
                  content: `Are you sure you want to delete ${deleteText}?`,
                  okText: 'Delete',
                  type: 'Delete',
                  onOk: () => {
                    handleDelete();
                  },
                });
              }}
            >
              Delete
            </Button>
          ) : null
        }
        <Button
          type="primary"
          disabled={!unsavedChanges}
          onClick={() => handleSave()}
        >
          Save changes
        </Button>
      </Space>
    </div>
  );
}

DetailPageToolBar.defaultProps = {
  handleDelete: null,
  path: null,
  isDisabled: false,
};

export default DetailPageToolBar;
