import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  FeedType, PagingDataResponse, ResponseError, Status,
} from '../../types';
import { selectToken } from '../../utils';
import { RootState } from '../index';

export enum ProductType {
  system = 'system',
  community = 'community'
}

export interface Product {
  id: string,
  productType?: ProductType,
  imageUrl?: string,
  nameEn: string,
  nameNo?: string,
  user?: {
    id: string,
    email: string
  } | null,
  users?: number,
  descriptionEn?: string,
  descriptionNo?: string,
  nutrientsType: string,
  feedType: FeedType | undefined,
  status: Status,
  energy: number | undefined,
  dryMatter: number | undefined,
  crudeProtein?: number,
  lysine?: number,
  calcium?: number,
  phosphorus?: number,
  magnesium?: number,
  sodium?: number,
  potassium?: number,
  sulfur?: number,
  iron?: number,
  copper?: number,
  manganese?: number,
  zinc?: number,
  selenium?: number,
  cobalt?: number,
  iodine?: number,
  vitaminA?: number,
  vitaminD?: number,
  vitaminE?: number,
  vitaminB1?: number,
  vitaminB2?: number,
  vitaminB6?: number,
  vitaminB12?: number,
  niacin?: number,
  folicAcid?: number,
  biotin?: number,
  vitaminC?: number,
  sugar?: number,
  ca_p?: number,
  protein_energy?: number,
  ca_mg?: number,
  zn_cu?: number,
  mn_cu?: number,
  fe_cu?: number,
  fiber?: number,
  fat?: number,
  starch?: number,

}

export interface ProductGetParams {
  type?: string[],
  feedType?: string,
  status?: string,
  productType?: string,
  page?: string[],
  limit?: string[],
  search?: string[],
  country?: string[],
  orderBy?: string[],
  orderByColumn?: string[],
}

export interface ProductUsersParams {
  productId?: string,
  page?: string[],
  limit?: string[],
  country?: string[],
  orderBy?: string[],
  orderByColumn?: string[],
}

export interface ProductUsers {
  id: string,
  name: string,
  city: string,
  country: string,
}

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProducts: builder.query<PagingDataResponse<Product>, ProductGetParams>({
      query: (params) => ({
        url: '/admin/products',
        method: 'GET',
        params,
      }),
    }),
    createProduct: builder.mutation<string, Omit<Product, 'productType'>>({
      query: (formData) => ({
        url: '/admin/products',
        method: 'POST',
        body: formData,
      }),
    }),
    getProduct: builder.mutation<Product, string>({
      query: (id) => ({
        url: `/admin/products/${id}`,
        method: 'GET',
      }),
    }),
    editProduct: builder.mutation<string, { formData: Product, id: string}>({
      query: ({ formData, id }) => ({
        url: `/admin/products/${id}`,
        method: 'PATCH',
        body: formData,
      }),
      transformErrorResponse: (error: ResponseError) => error?.data?.message?.join(', '),
    }),
    deleteProduct: builder.mutation<string, string>({
      query: (id) => ({
        url: `/admin/products/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error: ResponseError) => error?.data?.message?.join(', '),
    }),
    getProductUsersList: builder.query<PagingDataResponse<ProductUsers>, ProductUsersParams>({
      query: (params) => ({
        url: '/admin/users/',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetProductsQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductMutation,
  useEditProductMutation,
  useLazyGetProductUsersListQuery,
} = productsApi;
