import React, { useEffect, useState } from 'react';
import Upload, { RcFile } from 'antd/es/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import ModalError from '../ModalError';

import styles from './index.module.scss';
import { useUploadImageMutation } from '../../../store/common';

interface UploadImage {
  handleImage: (imageUrl: string) => void
  className?: string,
  type?: 'product' | 'avatar'
  imageUrl?: string
}

export default function UploadImage({
  handleImage, className, type, imageUrl,
}:UploadImage) {
  const [
    uploadImage,
    {
      data, isLoading, isSuccess, isError,
    },
  ] = useUploadImageMutation();

  const [isValidImage, setIsValidImage] = useState<boolean>(false);

  const customRequest = ({ onSuccess, file }: UploadRequestOption) => {
    setTimeout(() => {
      if (onSuccess) {
        const formData = new FormData();

        formData.append('file', file);
        uploadImage(formData);
        onSuccess('ok');
      }
    }, 100);
  };

  const handleBeforeUpload = (file: RcFile) => {
    const isValidType = file.type === 'image/jpeg' || file.type === 'image/png';
    const isValid = file.size / 1024 / 1024 <= 10;

    setIsValidImage(!(isValidType && isValid));

    return isValidType && isValid;
  };

  useEffect(() => {
    if (data?.url) {
      handleImage(data.url);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isValidImage) {
      ModalError({
        title: 'Error',
        content: 'Unsupported image file. Recommended formats: JPEG, PNG. Recommended size: less than 10 Mb.',
        onOk: () => setIsValidImage(false),
      });
    }
  }, [isValidImage]);

  return (
    <Row gutter={10} className={styles.uploaderWrap}>
      <Col span={type === 'avatar' ? 4 : 3} className={styles.label}>
        Image:
      </Col>
      <Col span={type === 'avatar' ? 20 : 21}>
        <Upload
          listType="picture-card"
          showUploadList={false}
          multiple={false}
          accept="image/png, image/jpeg"
          customRequest={customRequest}
          beforeUpload={handleBeforeUpload}
          className={clsx(data?.url || imageUrl ? styles.withImage : '', className)}
        >
          {data?.url || imageUrl ? (
            <img
              src={`${process.env.REACT_APP_BUCKET_URL}${data?.url || imageUrl}`}
              alt={data?.url || imageUrl}
              style={{ width: '100%' }}
            />
          ) : (
            <div>
              {isLoading ? <LoadingOutlined className={styles.icon} /> : <PlusOutlined className={styles.icon} />}
              <div style={{ marginTop: 5, opacity: 0.45 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Col>
    </Row>
  );
}

UploadImage.defaultProps = {
  className: '',
  type: 'product',
  imageUrl: undefined,
};
