import React, { useMemo } from 'react';
import {
  Form, Input, FormInstance, DatePicker,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import dayjs, { Dayjs } from 'dayjs';
import { updateUnsavedChanges } from '../../../../store/unsavedChanges';
import SelectStatus from '../../../Common/SelectStatus';
import { UserDetail } from '../../../../store/users';
import SelectCountry from '../../../Common/SelectCountry';
import { clearInitialData, compareObjects } from '../../../../utils';
import { useAppDispatch } from '../../../../hooks/redux';
import SelectGender from '../../../Common/SelectGender';
import HorsesAndProducts from './HorsesAndProducts';
import ActivityAndStatistics from './ActivityAndStatistics';
import ContentTitle from '../../../Common/ContentTitle';

import styles from './index.module.scss';

interface UserDetailPageForm {
  handleFinish: (data: UserDetail) => void,
  formRef: React.RefObject<FormInstance>,
  initialValues: UserDetail | undefined,
}

const dateFormat = 'DD/MM/YYYY';

function disabledDate(current: Dayjs | null): boolean {
  return current ? current.isAfter(dayjs().endOf('day')) : false;
}

const defaultValues: UserDetail = {
  name: '',
  email: '',
  id: '',
  city: '',
  address: '',
};

export default function UserDetailForm({
  handleFinish, formRef, initialValues,
}: UserDetailPageForm) {
  const dispatch = useAppDispatch();

  const handleFormValuesChange = (changedValues: Store, allValues: Store) => {
    dispatch(updateUnsavedChanges(!compareObjects(allValues, initialValues || defaultValues)));
  };

  const clearedInitialValues = useMemo<UserDetail>(
    () => clearInitialData<UserDetail>(initialValues || defaultValues),
    [initialValues],
  );

  return (
    <Form
      ref={formRef}
      name="userDetailPage"
      autoComplete="off"
      onFinish={handleFinish}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
      initialValues={{
        ...clearedInitialValues,
        country: clearedInitialValues.country?.id,
        dateOfBirth: clearedInitialValues.dateOfBirth ? dayjs(clearedInitialValues.dateOfBirth).locale('en') : null,
      }}
      onValuesChange={handleFormValuesChange}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'This field is required and cannot be empty.',
          },
          {
            min: 1,
            max: 100,
            message: 'Min: 1, Max characters: 100',
            validator: (rule, value, callback) => {
              if ((value && value.trim() === '') || value?.length > 100) {
                callback('Min: 1, Max characters: 100');
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: '', warningOnly: true }]}
        validateStatus="success"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item name="dateOfBirth" label="Date of birth">
        <DatePicker format={dateFormat} style={{ width: '100%' }} disabledDate={disabledDate} />
      </Form.Item>
      <Form.Item name="gender" label="Gender">
        <SelectGender />
      </Form.Item>
      <Form.Item name="country" label="Country">
        <SelectCountry placeholder="Please enter" />
      </Form.Item>
      <Form.Item name="city" label="City">
        <Input disabled />
      </Form.Item>
      <Form.Item name="address" label="Address">
        <Input disabled />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <SelectStatus />
      </Form.Item>
      <div className={styles.titleStatistics}>
        <ContentTitle title="Activity and Statistics" divider />
      </div>
      <Form.Item label="Overview" className="horses-products">
        <ActivityAndStatistics
          key="RegistrationDate"
          isFirst
          title="Registration date"
          value={
            clearedInitialValues?.createdAt ? (
              dayjs(clearedInitialValues.createdAt).locale('en').format('DD/MM/YYYY')
            ) : '-'
          }
        />
        <ActivityAndStatistics
          key="LastActivity"
          isFirst={false}
          title="Last activity"
          value={
            clearedInitialValues?.lastActivityAt ? (
              dayjs(clearedInitialValues.lastActivityAt).locale('en').format('DD/MM/YYYY')
            ) : '-'
          }
        />
        <ActivityAndStatistics
          key="calculatorUsage"
          isFirst={false}
          title="Last nutritional calculator usage"
          value={
            clearedInitialValues?.calculatorUsedAt ? (
              dayjs(clearedInitialValues.calculatorUsedAt).locale('en').format('DD/MM/YYYY')
            ) : '-'
          }
        />
        <ActivityAndStatistics
          key="NumLogins"
          isFirst={false}
          title="Number of logins"
          value={
            clearedInitialValues?.daysCount ? clearedInitialValues.daysCount : '-'
          }
        />
        <ActivityAndStatistics
          key="NumJournal"
          isFirst={false}
          title="Number of scheduled journal entries "
          value={
            clearedInitialValues?.notesNumber ? clearedInitialValues.notesNumber : '-'
          }
        />
        <ActivityAndStatistics
          key="NumNoteLogsNumber"
          isFirst={false}
          title="Number of logged journal entries (sub-notes)"
          value={
            clearedInitialValues?.noteLogsNumber ? clearedInitialValues.noteLogsNumber : '-'
          }
        />
        <ActivityAndStatistics
          key="NumWorkouts"
          isFirst={false}
          title="Number of sheduled workouts"
          value={
            clearedInitialValues?.workoutsNumber ? clearedInitialValues.workoutsNumber : '-'
          }
        />
        <ActivityAndStatistics
          key="NumWorkoutLogsNumber"
          isFirst={false}
          title="Number of logged workouts"
          value={
            clearedInitialValues?.workoutLogsNumber ? clearedInitialValues.workoutLogsNumber : '-'
          }
        />
      </Form.Item>
      <Form.Item label="Horse/Product" className="horses-products">
        <HorsesAndProducts horsesAndProduct={clearedInitialValues?.horses} />
      </Form.Item>
    </Form>
  );
}
