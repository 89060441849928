import React, { useEffect, useReducer } from 'react';
import { DatePicker, Row, Space } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Content } from 'antd/es/layout/layout';
import SelectTimePeriod, { ITimePeriod, TimePeriod } from '../../../Common/SelectTimePeriod';
import { RequiredKeys } from '../../../../types';

const rangePeriod: Record<RequiredKeys<ITimePeriod>, [Dayjs, Dayjs]> = {
  today: [dayjs(), dayjs()],
  yesterday: [dayjs().add(-1, 'd'), dayjs()],
  thisWeek: [dayjs().startOf('week').add(1, 'day'), dayjs()],
  lastWeek: [dayjs().startOf('week').subtract(1, 'week'), dayjs().startOf('week')],
  last7Days: [dayjs().add(-7, 'd'), dayjs()],
  last28Days: [dayjs().add(-28, 'd'), dayjs()],
  last30Days: [dayjs().add(-30, 'd'), dayjs()],
  last90Days: [dayjs().add(-90, 'd'), dayjs()],
  last12Months: [dayjs().subtract(11, 'month').startOf('month'), dayjs()],
  lastCalendarYear: [
    dayjs().subtract(1, 'year').startOf('year'),
    dayjs().startOf('year'),
  ],
  thisYear: [dayjs().startOf('year'), dayjs()],
};

interface UserActivityOverTime {
  handleRange: (range: [Dayjs, Dayjs]) => void
}

interface State {
  isCustom: boolean,
  selected: string,
  range: [Dayjs, Dayjs],
}

function PeriodPicker({ handleRange }: UserActivityOverTime) {
  const [state, dispatch] = useReducer(
    (prevState: State, action: Partial<State>) => (
      { ...prevState, ...action }
    ),
    {
      isCustom: false,
      selected: TimePeriod.last30Days,
      range: rangePeriod.last30Days,
    },
  );

  const handleSelectPeriod = (period: RequiredKeys<ITimePeriod>) => {
    dispatch({
      isCustom: false,
      selected: period,
      range: period in rangePeriod ? rangePeriod[period] : state.range,
    });
  };

  const handleChange = (value: [Dayjs, Dayjs]) => {
    dispatch({ range: value, isCustom: true });
  };

  useEffect(() => {
    handleRange(state.range);
  }, [state.range]);

  return (
    <Content className="content-wrap" style={{ marginBottom: 24 }}>
      <Row>
        <Space>
          <SelectTimePeriod
            value={state.isCustom ? TimePeriod.custom : state.selected}
            onChange={(value) => handleSelectPeriod(value as RequiredKeys<ITimePeriod>)}
          />
          <DatePicker.RangePicker
            allowClear={false}
            value={state.range}
            onChange={(value) => handleChange(value as [Dayjs, Dayjs])}
          />
        </Space>

      </Row>
    </Content>
  );
}

export default PeriodPicker;
