import React from 'react';
import config from '../../../store/auth';
import Layout from '../../../components/Layout';
import NutritionEdit from '../../../components/Pages/Nutrition/Edit';

const { displayName } = config;

export default function NutritionEditPage(): JSX.Element {
  document.title = `${displayName}: Nutrition Edit`;

  return (
    <Layout>
      <NutritionEdit />
    </Layout>
  );
}
