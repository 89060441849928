import React, { useEffect, useMemo, useState } from 'react';
import {
  Col, Form as AntdForm, FormInstance, Input, Radio, RadioChangeEvent, Row,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RuleObject } from 'rc-field-form/lib/interface';
import SelectFeedType from '../../../Common/SelectFeedType';
import SelectProductType from '../../../Common/SelectProductType';
import ContentTitle from '../../../Common/ContentTitle';
import SelectStatus from '../../../Common/SelectStatus';
import { Product, ProductType } from '../../../../store/nutrition';
import { FeedType, Status } from '../../../../types';
import { calculateRatios, clearInitialData, compareObjects } from '../../../../utils';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateUnsavedChanges } from '../../../../store/unsavedChanges';
import { calculatedValuesByDM } from '../Edit';

interface NutritionDetailPageForm {
  handleFinish: (data: Product) => void,
  formRef: React.RefObject<FormInstance>,
  initialValues?: Product,
}

const defaultValues: Product = {
  id: '',
  nameEn: '',
  nameNo: '',
  nutrientsType: 'feed',
  status: Status.active,
  energy: undefined,
  dryMatter: undefined,
  feedType: undefined,
};

const validateNumberFields = (_: RuleObject, value: string) => {
  if (!value) return Promise.resolve();
  if (value.length > 100) return Promise.reject(new Error('Max characters: 100'));

  if (/^\d+(,\d+)*(\.\d+)?$/.test(value)) return Promise.resolve();

  return Promise.reject(new Error('Invalid number format'));
};

export const replaceDotToComa = (data: Product) => {
  let updatedInitialValues = data;

  if (updatedInitialValues) {
    const initialValuesArr = Object.entries(updatedInitialValues).map(([key, value]) => [
      key,
      typeof value === 'number' ? value.toString().replace('.', ',') : value,
    ]);

    updatedInitialValues = Object.fromEntries(initialValuesArr);
  }

  return updatedInitialValues;
};

const handleCalculatedFormValues = (data: Product) => {
  const calculatedData: Product = {
    ...data,
    ca_p: calculateRatios(data.calcium, data.phosphorus),
    ca_mg: calculateRatios(data.calcium, data.magnesium),
    fe_cu: calculateRatios(data.iron, data.copper),
    mn_cu: calculateRatios(data.manganese, data.copper),
    zn_cu: calculateRatios(data.zinc, data.copper),
    protein_energy: calculateRatios(data.crudeProtein, data.energy),
  };

  return calculatedData;
};

const targets = ['calcium', 'iron', 'phosphorus', 'magnesium', 'copper', 'zinc', 'manganese', 'crudeProtein', 'energy'];

export default function NutritionDetailForm({ handleFinish, formRef, initialValues }: NutritionDetailPageForm) {
  const dispatch = useAppDispatch();
  const [isDM, setIsDM] = useState<boolean>(false);
  const [isSystem, setIsSystem] = useState<boolean>(!initialValues?.user);
  const [isForage, setIsForage] = useState<boolean>(initialValues?.feedType === FeedType.forage);

  const handleFormValuesChange = (changedValues: Partial<Product>, allValues: Product) => {
    let formValues = allValues;
    const updatedInitialValues = initialValues ? replaceDotToComa(initialValues) : defaultValues;

    if (changedValues.nutrientsType) {
      formValues = calculatedValuesByDM(allValues);
      formRef?.current?.setFieldsValue(replaceDotToComa(formValues));
    }

    if (changedValues.productType) {
      setIsSystem(changedValues.productType === 'system');
    }

    if (changedValues.feedType) {
      setIsForage(changedValues.feedType === FeedType.forage);
      formRef?.current?.setFields([{ name: 'energy', errors: [] }]);
    }

    if (targets.includes((Object.keys(changedValues) || []).join('')) || changedValues.nutrientsType) {
      formValues = handleCalculatedFormValues(formValues);
      formRef?.current?.setFieldsValue(replaceDotToComa(formValues));
    }

    dispatch(updateUnsavedChanges(!compareObjects(formValues, updatedInitialValues)));
  };

  const clearedInitialValues = useMemo<Product>(() => {
    const updatedInitialValues = initialValues ? (
      replaceDotToComa(handleCalculatedFormValues(calculatedValuesByDM(initialValues, false, true)))
    ) : defaultValues;

    return clearInitialData<Product>(updatedInitialValues);
  }, [initialValues]);

  useEffect(() => {
    setIsSystem(!initialValues?.user);
    setIsDM(initialValues?.nutrientsType === 'dryMatter');
  }, [initialValues]);

  return (
    <AntdForm
      ref={formRef}
      name="nutritionDetailPage"
      autoComplete="off"
      onFinish={(values) => {
        let formValues = values;

        if (values.nutrientsType === 'dryMatter') {
          formValues = calculatedValuesByDM(values, true);
        }

        const convertedValues = Object.fromEntries(
          Object.entries(formValues).map(([key, value]) => {
            const strValue = value?.toString() || null;

            const parsedValue = strValue?.includes(',')
              ? parseFloat(strValue?.replace(',', '.'))
              : strValue;

            return [key, Number.isNaN(parsedValue) ? value : parsedValue];
          }),
        ) as Product;

        handleFinish(convertedValues);
      }}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onValuesChange={handleFormValuesChange}
      initialValues={{
        ...clearedInitialValues,
        productType: clearedInitialValues?.user ? ProductType.community : ProductType.system,
      }}
    >
      <Row gutter={24}>
        <Col span={11}>
          {isSystem || !initialValues?.nameNo ? (
            <AntdForm.Item
              name="nameEn"
              label="Name"
              rules={[{
                required: true,
                message: 'This field is required and cannot be empty.',
              },
              {
                min: 1,
                max: 100,
                message: 'Min: 1, Max characters: 100',
                validator: (rule: RuleObject, value: string) => (
                  value && value.trim() === '' ? (
                    Promise.reject(new Error('Min: 1, Max characters: 100'))
                  ) : Promise.resolve()
                ),
              }]}
            >
              <Input placeholder="Please enter" />
            </AntdForm.Item>
          ) : null}
          {isSystem || initialValues?.nameNo ? (
            <AntdForm.Item
              name="nameNo"
              label="Name(No)"
              rules={[{
                required: true,
                message: 'This field is required and cannot be empty.',
              },
              {
                min: 1,
                max: 100,
                message: 'Min: 1, Max characters: 100',
                validator: (rule: RuleObject, value: string) => (
                  value && value.trim() === '' ? (
                    Promise.reject(new Error('Min: 1, Max characters: 100'))
                  ) : Promise.resolve()
                ),
              }]}
            >
              <Input placeholder="Please enter" />
            </AntdForm.Item>
          ) : null}
          <AntdForm.Item
            name="feedType"
            label="Feed type"
            rules={[{ required: true, message: 'This field is required and cannot be empty.' }]}
          >
            <SelectFeedType />
          </AntdForm.Item>
          <AntdForm.Item
            name="productType"
            label="Product list"
            rules={[{ required: true, message: 'This field is required and cannot be empty.' }]}
          >
            <SelectProductType />
          </AntdForm.Item>
          {isSystem || !initialValues?.nameNo ? (
            <AntdForm.Item
              name="descriptionEn"
              label="Description"
            >
              <TextArea placeholder="Product description" rows={4} />
            </AntdForm.Item>
          ) : null }
          { isSystem || initialValues?.nameNo ? (
            <AntdForm.Item
              name="descriptionNo"
              label="Description(No)"
            >
              <TextArea placeholder="Product description" rows={4} />
            </AntdForm.Item>
          ) : null }
        </Col>
        <Col span={13}>
          <AntdForm.Item
            name="nutrientsType"
            label="Nutrients"
          >
            <Radio.Group
              options={[
                { label: 'Per kg/feed', value: 'feed' },
                { label: 'Per kg/dry matter', value: 'dryMatter' },
              ]}
              optionType="button"
              onChange={({ target: { value } }: RadioChangeEvent) => {
                setIsDM(value === 'dryMatter');
              }}
              buttonStyle="solid"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="dryMatter"
            label="Dry matter"
            rules={[
              { required: true, message: 'This field is required and cannot be empty.' },
              {
                validator: (_: RuleObject, value: string) => {
                  if (!value) return Promise.resolve();
                  if (+value < 1 || +value > 100) {
                    return Promise.reject(new Error('Number must be between 1 and 100'));
                  }
                  if (/^\d+(\.\d+)?$/.test(value)) return Promise.resolve();

                  return Promise.reject(new Error('Invalid number format'));
                },
              },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix="%"
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="energy"
            label="Energy"
            rules={[
              {
                required: isForage,
                message: 'This field is required and cannot be empty.',
              },
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`UFC/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="crudeProtein"
            label="Digestible Crude Protein (DCP)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="status"
            label="Status"
          >
            <SelectStatus />
          </AntdForm.Item>
        </Col>
      </Row>
      <ContentTitle title="Minerals" divider />
      <Row gutter={24}>
        <Col span={12}>
          <AntdForm.Item
            name="lysine"
            label="Lysine"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="calcium"
            label="Calcium (Ca)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="phosphorus"
            label="Phosphorus (P)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="magnesium"
            label="Magnesium (Mg)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="sodium"
            label="Sodium (Na)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="potassium"
            label="Potassium (K)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="sulfur"
            label="Sulfur (S)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="iron"
            label="Iron (Fe)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="copper"
            label="Copper (Cu)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="manganese"
            label="Manganese (Mn)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="zinc"
            label="Zinc (Zn)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="selenium"
            label="Selenium (Se)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="cobalt"
            label="Cobalt (Co)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="iodine"
            label="Iodine (I)"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminA"
            label="Vitamin A"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`IU/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminD"
            label="Vitamin D"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`IU/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminE"
            label="Vitamin E"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminB1"
            label="Vitamin B1"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
        </Col>
        <Col span={12}>
          <AntdForm.Item
            name="vitaminB2"
            label="Vitamin B2"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminB6"
            label="Vitamin B6"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminB12"
            label="Vitamin B12"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="niacin"
            label="Niacin"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="folicAcid"
            label="Folic Acid"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="biotin"
            label="Biotin"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="vitaminC"
            label="Vitamin C"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`mg/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="sugar"
            label="Sugar"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g/kg ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="starch"
            label="Starch"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="fiber"
            label="Fiber"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="fat"
            label="Fat"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              suffix={`g ${isDM ? 'DM' : ''}`}
              placeholder="Please enter"
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="protein_energy"
            label="Crude protein/Energy"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input placeholder="Please enter" disabled />
          </AntdForm.Item>
          <AntdForm.Item
            name="ca_p"
            label="Ca/P"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input
              placeholder="Please enter"
              disabled
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="ca_mg"
            label="Ca/Mg"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input placeholder="Please enter" disabled />
          </AntdForm.Item>
          <AntdForm.Item
            name="zn_cu"
            label="Zn/Cu"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input placeholder="Please enter" disabled />
          </AntdForm.Item>
          <AntdForm.Item
            name="mn_cu"
            label="Mn/Cu"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input placeholder="Please enter" disabled />
          </AntdForm.Item>
          <AntdForm.Item
            name="fe_cu"
            label="Fe/Cu"
            rules={[
              { validator: validateNumberFields },
            ]}
            normalize={(value) => value.replace(/[^0-9,]/g, '')}
          >
            <Input placeholder="Please enter" disabled />
          </AntdForm.Item>

        </Col>
      </Row>
    </AntdForm>
  );
}

NutritionDetailForm.defaultProps = {
  initialValues: undefined,
};
