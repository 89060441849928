import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { ProductsByType } from '../../../../../store/dashboard';

import styles from './index.module.scss';

interface FeedTypeFilters {
  filters: ProductsByType[] | []
  handleSetFilter: (value: string) => void,
  currentFilter: string
}

export default function FeedTypeFilters({
  filters,
  handleSetFilter,
  currentFilter,
}: FeedTypeFilters) {
  return (
    <div className={styles.wrapper}>
      {filters?.map(({ name, count }: ProductsByType) => (
        <Button
          key={name}
          type="link"
          onClick={() => handleSetFilter(name.toLowerCase())}
          className={clsx(styles.buttons, currentFilter === name.toLowerCase() ? styles.active : '')}
        >
          {name}
          <br />
          <strong className={styles.counter}>{count}</strong>
        </Button>
      ))}
    </div>
  );
}
