import React from 'react';
import Layout from '../../components/Layout';
import config from '../../store/auth';
import PageUserList from '../../components/Pages/Users';
import Content from '../../components/Common/Wrapper/Content';
import PageHeader from '../../components/Common/PageHeader';

const { displayName } = config;

export default function UsersPage(): JSX.Element {
  document.title = `${displayName}: Users`;

  return (
    <Layout>
      <Content>
        <PageHeader title="Users" />
        <PageUserList />
      </Content>
    </Layout>
  );
}
