import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Col, Row } from 'antd';
import UserActivityOverTime from './PeriodPicker';
import ActivityOverTime from './ActivityOverTime';
import Retention from './Retention';
import UsersByLocation from './UsersByLocation';
import UsersByNutrition from './UsersByNutrition';
import UsersActivitiesTable from './UsersActivities';
import { useRetentionMutation, useLazyUserActivityQuery } from '../../../store/dashboard';

export default function Dashboard(): JSX.Element {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(
    [dayjs().add(-30, 'd'), dayjs()],
  );

  const [fetchUserActivity,
    { data: userActivityData, isLoading: userActivityIsLoading }] = useLazyUserActivityQuery();
  const [fetchRetention,
    { data: retentionData, isLoading: retentionIsLoading }] = useRetentionMutation();

  const handleRange = async (range: [Dayjs, Dayjs]) => {
    if (!range) {
      return;
    }

    setDateRange(range);
    const fromDate = range[0].toDate().toISOString();
    const tillDate = range[1].toDate().toISOString();

    await fetchUserActivity({ fromDate, tillDate });
    await fetchRetention({ fromDate, tillDate });
  };

  return (
    <>
      <UserActivityOverTime handleRange={handleRange} />
      <Row gutter={24}>
        <Col span={8}>
          <ActivityOverTime data={userActivityData} isLoading={userActivityIsLoading} />
        </Col>
        <Col span={16}>
          <Retention data={retentionData} isLoading={retentionIsLoading} range={dateRange} />
        </Col>
      </Row>
      <UsersActivitiesTable range={dateRange} />
      <Row gutter={24} style={{ marginTop: 24 }}>
        <Col span={12}>
          <UsersByLocation />
        </Col>
        <Col span={12}>
          <UsersByNutrition />
        </Col>
      </Row>
    </>
  );
}
