import React, { useRef } from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

interface UserActivityStatisticsType {
  isFirst: boolean,
  title: string,
  value: string | number,
}

export default function ActivityAndStatistics({ title, value, isFirst }: UserActivityStatisticsType) {
  return (
    <div className={clsx(styles.userActivity, isFirst ? styles.isFirst : '')}>
      <div>
        {title}
      </div>
      <div>
        {value}
      </div>
    </div>
  );
}
