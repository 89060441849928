import { Badge, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib/select';
import { Option } from '../../../types';

interface SelectStatusProps extends SelectProps<string, Option> {
  pending?: boolean,
}

export default function SelectStatus(
  { pending, ...props }: SelectStatusProps,
): JSX.Element {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    setOptions([
      { label: <Badge status="success" text="Active" />, value: 'active' },
      { label: <Badge status="default" text="Inactive" />, value: 'inactive' },
    ]);
  }, []);

  useEffect(() => {
    if (pending) {
      setOptions((prevState) => ([
        ...prevState,
        { label: <Badge status="warning" text="Pending" />, value: 'pending' },
      ]));
    }
  }, [pending]);

  return (
    <Select placeholder="Please select" options={options} allowClear {...props} />
  );
}

SelectStatus.defaultProps = {
  pending: false,
};
