import React, { useRef } from 'react';
import { FormInstance } from 'antd';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Link } from 'react-router-dom';
import Table from '../../../../Common/Table';
import { addKeyToTableData } from '../../../../../utils';
import { HorseAndProduct } from '../../../../../store/users';

interface HorseAndProductTable {
  horsesAndProduct: HorseAndProduct[] | [] | undefined
}
type Product = {
  horse?: string,
  id?: string | undefined,
  nameEn?: string | undefined,
  nameNo?: string | undefined,
}

export default function HorsesAndProducts({ horsesAndProduct } : HorseAndProductTable) {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>,
  ): Promise<RequestData<Product>> => {
    const transformedHorsesAndProduct = horsesAndProduct?.map((element) => ({
      horse: element.name,
      products: element.products,
    }));
    const updatedDataSourse = transformedHorsesAndProduct?.map((element) => {
      const updatedProduct = element.products?.map((item, index: number) => {
        if (index !== 0) {
          return { ...item, horse: '' };
        }

        return { ...item, horse: element.horse };
      });

      return { ...element, products: updatedProduct };
    });

    const updatedProductArray = updatedDataSourse?.map((element) => {
      if (element.products && element.products.length > 0) {
        return element.products?.map((product) => ({
          id: product.id,
          name: product.nameEn || product.nameNo,
          horse: product.horse,
        }));
      }

      return {
        id: '',
        name: '',
        horse: element.horse,
      };
    }).flat();

    return ({ data: updatedProductArray ? addKeyToTableData(updatedProductArray) : [] });
  };

  const columns: ProColumns<Product>[] = [
    {
      title: 'Horse',
      dataIndex: 'horse',
      className: 'horse-product-head',
      width: '42%',
      sorter: false,
      renderText: (text) => (text || ' '),
    },
    {
      title: 'Product',
      dataIndex: 'name',
      className: 'horse-product-head',
      width: '58%',
      sorter: false,
      renderText: (text, { id }) => (
        <Link to={`/nutrition/${id}`}>{text || ''}</Link>
      ),
    },
  ];

  return (
    <Table
      className="customToolbar hide-native-search padding-inline"
      syncToUrl={false}
      formRef={formRef}
      options={{ reload: false, setting: false }}
      actionRef={actionRef}
      showSorterTooltip={false}
      columns={columns}
      rowClassName="horse-product-row"
      request={tableRequest}
      pagination={false}
    />
  );
}
