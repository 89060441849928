import {
  config,
  createReducers,
  getLocalState,
  saga as authSaga,
  setActions,
  State as AuthState,
  updateConfig,
} from '@triare/auth-redux';
import {
  createAsyncThunk, createSlice, EnhancedStore, SliceCaseReducers,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { Token } from '@triare/auth-redux/dist/saga/auth';
import { spawn } from 'redux-saga/effects';
import { HttpMethod } from '@triare/auth-redux/dist/config';
import { SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { RootState } from '../index';

export * from '@triare/auth-redux';

export default updateConfig({
  name: 'auth',
  displayName: 'equip',
  fetchDelay: process.env.REACT_APP_ENV === 'local' ? 2000 : 0,
  api: {
    url: process.env.REACT_APP_API || '',
  },
  signIn: {
    url: {
      byEmail: () => 'auth/login/email',
    },
    delayError: 3000,
    requestBody: {
      byEmail: ({ email, password }: SignInAction) => JSON.stringify({ email, password }),
    },
  },
  userMe: {
    url: 'admin/users/me',
  },
  refreshToken: {
    method: HttpMethod.POST,
    url: 'auth/refresh-token',
  },
});

export interface OTP {
  secretKey: string,
  secretCode: string
}

export interface Tokens {
  access: Token
  refresh: Token
}

export interface State extends AuthState {
  secretKey: string
}

export const auth = createSlice<State, SliceCaseReducers<State>>({
  name: config.auth.name,
  initialState: getLocalState() as State,
  reducers: {
    ...createReducers(),
    clearError: (state) => {
      state.error = null;
    },
    clearSecretKey: (state) => {
      state.secretKey = '';
    },
  },
  extraReducers: (builder) => {
    // forgot password
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = {
        message: payload as string,
      };
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.secretKey = payload.secretKey;
    });

    // OTP code
    builder.addCase(verificationCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verificationCode.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = {
        message: payload as string,
      };
    });
    builder.addCase(verificationCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.access = payload.access;
      state.refresh = payload.refresh;
      state.authorized = true;
    });

    // Reset password
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = {
        message: payload as string,
      };
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
  },
});

export const { clearError, clearSecretKey } = auth.actions;

export const { reducer, actions } = auth;

setActions(actions);

export function* saga(store: EnhancedStore) {
  yield spawn(authSaga, store);
}

const instance = () => axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const forgotPassword = createAsyncThunk(
  `${config.auth.name}/forgotPassword`,
  async (email: string, { rejectWithValue, dispatch }) => {
    dispatch(clearError(null));

    return instance().post<{secretKey: string}>('/auth/forgot-password', { email })
      .then((res) => res.data)
      .catch((error) => rejectWithValue(error.response.data.message));
  },
);

export const verificationCode = createAsyncThunk(
  `${config.auth.name}/verification`,
  async (payload: OTP, { dispatch, rejectWithValue }) => {
    dispatch(clearError(null));

    return instance().post<Tokens>('/auth/forgot-password/confirm', payload)
      .then((res) => res.data)
      .catch((error) => rejectWithValue(error.response.data.message));
  },
);

export const resetPassword = createAsyncThunk(
  `${config.auth.name}/reset-password`,
  async (payload: {password: string, secretKey: string}, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as RootState;

    dispatch(clearError(null));

    return instance().post('/auth/reset-password', payload, {
      headers: {
        Authorization: `Bearer ${state.auth.access?.token}`,
      },
    }).then((res) => res.data)
      .catch((error) => rejectWithValue(error.response.data.message));
  },
);
