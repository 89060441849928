import React from 'react';
import config from '../../store/auth';
import Content from '../../components/Common/Wrapper/Content';
import Layout from '../../components/Layout';
import PageHeader from '../../components/Common/PageHeader';
import Dashboard from '../../components/Pages/Dashboard';

const { displayName } = config;

export default function DashboardPage(): JSX.Element {
  document.title = `${displayName}: Dashboard`;

  return (
    <Layout>
      <Content>
        <PageHeader title="Dashboard" />
        <Dashboard />
      </Content>
    </Layout>
  );
}
