import React from 'react';
import config from '../../store/auth';
import Content from '../../components/Common/Wrapper/Content';
import AuthLayout from '../../components/Layout/Auth';
import ForgotPassword from '../../components/Pages/ForgotPassword';

const { displayName } = config;

export default function ForgotPasswordPage(): JSX.Element {
  document.title = `${displayName}: Forgot Password`;

  return (
    <AuthLayout>
      <Content>
        <ForgotPassword />
      </Content>
    </AuthLayout>
  );
}
