import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib/select';
import { GenderType, Option } from '../../../types';
import { capitalizeFirstLetter } from '../../../utils';

export default function SelectGender({ ...props }: SelectProps<string, Option>): JSX.Element {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (GenderType) {
      setOptions(
        Object.entries(GenderType)
          .map(([key, value]): Option => ({ label: capitalizeFirstLetter(value), value: key })),
      );
    }
  }, []);

  return (
    <Select options={options} allowClear placeholder="Please select" {...props} />
  );
}
