import React, { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { Button, Typography } from 'antd';

import styles from './index.module.scss';

interface OTPForm {
  values: string,
  isLoading: boolean,
  isError: boolean,
  attempts: number,
  handleChange: (value: string) => void,
  handleResendCode: () => void,
  handleSendCode: () => void,
  handleClearCode: () => void,
}

export const exceptThisSymbols = ['e', 'E', '+', '-', '.', ','];

function OTPForm({
  handleChange,
  values,
  isLoading,
  isError,
  attempts,
  handleResendCode,
  handleSendCode,
  handleClearCode,
}: OTPForm): JSX.Element {
  const [countdown, setCountdown] = useState<number>(120);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevState) => prevState - 1);
    }, 1000);

    if (!countdown) {
      clearInterval(timer);
      handleClearCode();
    }

    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    if (!attempts) {
      setCountdown(0);
    }
  }, [attempts]);

  useEffect(() => {
    setShowError(isError);

    setTimeout(() => setShowError(false), 2000);
  }, [isError]);

  const formatTime = (time: number) => {
    let minutes = Math.floor(time / 60).toString();
    let seconds = (time % 60).toString();

    minutes = minutes.padStart(2, '0');
    seconds = seconds.padStart(2, '0');

    return `${minutes}:${seconds}`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;

    if (!regex.test(e.key) || exceptThisSymbols.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <OTPInput
        containerStyle={styles.container}
        inputStyle={styles.OTPInput}
        value={values}
        shouldAutoFocus
        numInputs={4}
        inputType="number"
        onChange={handleChange}
        renderInput={(props) => (
          <input
            {...props}
            disabled={!countdown || isLoading}
            pattern="[0-9]*"
            type="number"
            onKeyDown={(e) => handleKeyDown(e)}
          />
        )}
      />
      {
        showError ? (
          <Typography.Paragraph className={styles.error}>Incorrect code.</Typography.Paragraph>
        ) : null
      }
      <Typography.Paragraph className={styles.receiveCode}>
        Don’t receive a code?
        <Button
          type="link"
          className={styles.resend}
          disabled={!!countdown}
          onClick={() => {
            handleResendCode();
            setCountdown(120);
          }}
        >
          {`Resend ${countdown ? `in ${formatTime(countdown)}` : ''}`}
        </Button>
      </Typography.Paragraph>
      <Button
        style={{ width: '100%' }}
        type="primary"
        disabled={values.length < 4 || !countdown}
        onClick={() => handleSendCode()}
      >
        Verify
      </Button>
    </>
  );
}

export default OTPForm;
