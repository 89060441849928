import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import { Route } from '../../../routes';

import styles from './index.module.scss';
import PrivateRoute from '../../../routes/PrivateRoute';
import DashboardPage from '../../../pages/Dashboard';
import UsersPage from '../../../pages/Users';
import NutritionPage from '../../../pages/Nutrition';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import ModalConfirm from '../../Common/ModalConfirm';
import { updateUnsavedChanges } from '../../../store/unsavedChanges';
import CreateNotificationPage from '../../../pages/CreateNotification';

export const routes: Route[] = [
  {
    name: 'Dashboard',
    icon: <AppstoreOutlined />,
    privateRoute: true,
    route: {
      path: 'dashboard',
      element: <PrivateRoute><DashboardPage /></PrivateRoute>,
    },
  },
  {
    name: 'Users',
    icon: <TeamOutlined />,
    privateRoute: true,
    route: {
      path: 'users',
      element: <PrivateRoute><UsersPage /></PrivateRoute>,
    },
  },
  {
    name: 'Nutrition',
    icon: <EnvironmentOutlined />,
    privateRoute: true,
    route: {
      path: 'nutrition',
      element: <PrivateRoute><NutritionPage /></PrivateRoute>,
    },
  },
  {
    name: 'Create notification',
    icon: <MailOutlined />,
    privateRoute: true,
    route: {
      path: 'createNotification',
      element: <PrivateRoute><CreateNotificationPage /></PrivateRoute>,
    },
  },
];

const createItem = (data: Route) => {
  const {
    icon, route, children, onClick,
  } = data;

  return {
    onClick,
    children: children?.map((child) => ({
      onClick: child.onClick,
      icon: child.icon,
      label: child.route ? (
        <NavLink to={`/${child.route.path}`}>{child.name}</NavLink>
      ) : child.name,
      key: child.route ? `/${child.route.path}` : `/${child.name}`,
    })),
    icon,
    label: route ? (
      <NavLink to={`/${route.path}`}>{data.name}</NavLink>
    ) : data.name,
    key: route ? `/${route.path}` : `/${data.name}`,
  };
};

export const mainMenu = routes.map(createItem);

function Menu(): JSX.Element {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { unsavedChanges } = useAppSelector((state) => state.unsavedChanges);
  const navigate = useNavigate();

  const [key, setKey] = useState<string>('');

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  return (
    <AntdMenu
      theme="light"
      mode="inline"
      selectedKeys={[pathname, `/${key}`]}
      defaultSelectedKeys={[pathname, `/${key}`]}
      className={styles.menu}
      items={mainMenu}
      onClickCapture={(e) => {
        if (unsavedChanges) {
          e.preventDefault();

          ModalConfirm({
            title: 'Unsaved changes',
            content: 'You have unsaved changes. Discard changes and leave?',
            okText: 'Discard',
            type: 'Discard',
            onOk: () => {
              dispatch(updateUnsavedChanges(false));
              const path = new URL((e.target as HTMLAnchorElement).href).pathname;

              navigate(path);
            },
          });
        }
      }}
    />
  );
}

export default Menu;
