import React from 'react';
import config from '../../../store/auth';
import Layout from '../../../components/Layout';
import UserEdit from '../../../components/Pages/Users/Edit';

const { displayName } = config;

export default function UserEditPage(): JSX.Element {
  document.title = `${displayName}: User edit`;

  return (
    <Layout>
      <UserEdit />
    </Layout>
  );
}
