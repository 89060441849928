import React from 'react';
import config from '../../store/auth';
import Layout from '../../components/Layout';
import PageHeader from '../../components/Common/PageHeader';
import NutritionTable from '../../components/Pages/Nutrition';

const { displayName } = config;

export default function NutritionPage(): JSX.Element {
  document.title = `${displayName}: Nutrition`;

  return (
    <Layout>
      <PageHeader title="Nutrition" />
      <NutritionTable />
    </Layout>
  );
}
