import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';

import styles from './index.module.scss';

interface Confirm {
  onOk: () => void;
  title: string;
  content: string | JSX.Element;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
  disabled?: boolean;
  type?: 'Delete' | 'Confirm' | 'Discard'
}

export default function ModalConfirm({
  onOk,
  onCancel,
  title,
  type = 'Confirm',
  content,
  okText = 'Confirm',
  cancelText = 'Cancel',
  disabled,
}: Confirm) {
  Modal.confirm({
    className: styles.confirmModal,
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText,
    transitionName: '',
    cancelText,
    onOk,
    onCancel,
    cancelButtonProps: { className: styles.cancelButton },
    okButtonProps: {
      disabled,
      className: type !== 'Confirm' ? styles.delete : '',
    },
  });
}
