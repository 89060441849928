import React from 'react';

import styles from './index.module.scss';

interface ToolTipProps {
  title: string,
  items: any[]
}

export default function ToolTip({ title, items }: ToolTipProps) {
  return (
    <div className={styles.toolTip}>
      <div className={styles.title}>{title}</div>
      <div className={styles.contentWrap}>
        <div>Users</div>
        <div>{items.map((el) => `${el.value}%`)}</div>
      </div>
    </div>
  );
}
