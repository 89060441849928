import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { Layout } from '../index';

import styles from './index.module.scss';

export default function AuthLayout({ children }: Layout): JSX.Element {
  return (
    <AntdLayout>
      <AntdLayout.Content className={styles.content}>
        {children}
      </AntdLayout.Content>
    </AntdLayout>
  );
}
