import React, { useState } from 'react';
import { Button, Form as AntdForm, Input } from 'antd';
import { createRulesForAntd, validationNewPassword } from '../../../../utils/validations';
import { validateTrigger } from '../../../../types';

const validationRules = createRulesForAntd(validationNewPassword);

interface ResetPasswordForm {
  onFinish: (formData: {password: string, confirmPassword: string}) => void,
  isLoading: boolean,
  isError: boolean,
}

function ResetPasswordForm({ onFinish, isLoading }: ResetPasswordForm) {
  const [form] = AntdForm.useForm();
  const [isFinishFailed, setIsFinishFailed] = useState<boolean>(false);
  const [validationType, setValidationType] = useState({
    password: { validateTrigger: validateTrigger.onBlur },
  });

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValidationType({
      ...validationType,
      [e.target.name]: { validateTrigger: validateTrigger.onChange },
    });
  };

  const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore ignore this line
    if (isFinishFailed && validationType[e.target.name].validateTrigger === validateTrigger.onBlur) {
      form.setFields([{ name: e.target.name, errors: [] }]);
    }
  };

  return (
    <AntdForm
      form={form}
      className="authForm"
      name="resetPassword"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={() => setIsFinishFailed(true)}
    >
      <AntdForm.Item
        name="password"
        className="item"
        rules={[validationRules]}
        validateTrigger={validationType.password.validateTrigger}
      >
        <Input.Password
          name="password"
          autoComplete="new-password"
          placeholder="New password"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </AntdForm.Item>
      <AntdForm.Item
        className="item"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Passwords do not match.'));
            },
          }),
        ]}
      >
        <Input.Password
          autoComplete="confirm-password"
          placeholder="Confirm password"
          onBlur={() => form.validateFields(['confirmPassword'])}
        />
      </AntdForm.Item>
      <AntdForm.Item
        className="submit"
        style={{ margin: 0 }}
      >
        <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%' }}>
          Reset password
        </Button>
      </AntdForm.Item>
    </AntdForm>
  );
}

export default ResetPasswordForm;
