import React, { useEffect } from 'react';
import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
interface Notification {
  type: NotificationType;
  message: string;
}

export default function Notification({ type, message }: Notification): JSX.Element {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = () => {
    api[type]({
      placement: 'top',
      message: '',
      duration: 3,
      description: message,
    });
  };

  useEffect(() => {
    openNotificationWithIcon();
  }, []);

  return (
    <div>
      {contextHolder}
    </div>
  );
}
