import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Typography } from 'antd';
import clsx from 'clsx';
import { UserActivity } from '../../../../store/dashboard';
import Period from './Period';
import Loading from '../../../Common/Loading';
import EmptyPlaceholder from '../../../Common/Empty';

import styles from './index.module.scss';

interface ActivityOverTimeProps {
  data: UserActivity[] | undefined
  isLoading: boolean
}

export default function ActivityOverTime({ data, isLoading }: ActivityOverTimeProps) {
  return (
    <Content className={clsx('content-wrap', styles.minHeight)}>
      <Loading visible={isLoading} />
      <Typography.Title level={5} style={{ marginBottom: 15 }}>User activity over time</Typography.Title>
      {
        data?.length ? (
          data?.map(({ period, days, value }, index) => (
            <Period period={period} value={value} days={days} key={days} isLast={index === data.length - 1} />
          ))
        ) : (
          <EmptyPlaceholder />
        )
      }
    </Content>
  );
}
