import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib/select';
import { Option } from '../../../types';
import { capitalizeFirstLetter } from '../../../utils';
import { useGetCitiesQuery } from '../../../store/common';

export default function SelectCity({ ...props }: SelectProps<string, Option>): JSX.Element {
  const [options, setOptions] = useState<Option[]>([]);

  const { data, isSuccess } = useGetCitiesQuery(null);

  useEffect(() => {
    if (data) {
      setOptions(
        data?.map((city): Option => ({ label: capitalizeFirstLetter(city), value: city.toLowerCase() })),
      );
    }
  }, [isSuccess]);

  return (
    <Select showSearch options={options} placeholder="City" allowClear {...props} />
  );
}
