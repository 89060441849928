import React from 'react';
import { Content } from 'antd/es/layout/layout';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import { Dayjs } from 'dayjs';
import clsx from 'clsx';
import ToolTip from './ToolTip';
import Loading from '../../../Common/Loading';
import EmptyPlaceholder from '../../../Common/Empty';

import styles from './index.module.scss';

interface RetentionProps {
  data: { day: string, value: number }[] | undefined,
  isLoading: boolean,
  range: [Dayjs, Dayjs]
}

export default function Retention({ data, isLoading, range }: RetentionProps) {
  const toolTop = (title: string, items: any[]) => <ToolTip items={items} title={title} />;

  const config = {
    data: data || [],
    height: 256,
    xField: 'day',
    yField: 'value',
    color: '#F3A482',
    scale: { y: { min: 0, max: 100 } },
    xAxis: { tickCount: 6 },
    tooltip: {
      customContent: (title: string, items: any[]) => toolTop(title, items),
    },
    point: { size: 0 },
  };

  return (
    <Content className={clsx('content-wrap', styles.minHeight)}>
      <Loading visible={isLoading} />
      <Typography.Title level={5} style={{ marginBottom: 0 }}>User retention</Typography.Title>
      <Typography.Paragraph className={styles.subTitle}>
        {`Last 42 days ending ${range[1].format('MMM DD')}`}
      </Typography.Paragraph>
      {
        data?.length ? (
          <Line
            yAxis={{
              tickCount: 6,
              position: 'right',
              label: {
                formatter: (value) => `${value}%`,
              },
            }}
            {...config}
          />
        ) : (
          <EmptyPlaceholder />
        )
      }
    </Content>
  );
}
