import React from 'react';
import { Button, Layout as AntdLayout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import Menu from './Menu';

import styles from './index.module.scss';
import MainLogo from '../Common/MainLogo';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import ModalConfirm from '../Common/ModalConfirm';

export interface Layout {
  children: React.ReactNode;
}

export default function Layout({ children }: Layout): JSX.Element {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  const handleLogOut = () => {
    dispatch(signOut());
  };

  return (
    <AntdLayout>
      <AntdLayout.Sider theme="light">
        <MainLogo styles={{ padding: '8px 0' }} />
        <Menu />
        <div className={styles.logoutWrapper}>
          <Button
            className={styles.logout}
            onClick={() => ModalConfirm({
              onOk: handleLogOut,
              title: 'Logout',
              okText: 'Log out',
              content: (
                <>
                  Are you sure you want to log out of selected user:
                  <strong style={{ marginLeft: 5 }}>
                    {user?.email}
                  </strong>
                  ?
                </>
              ),
            })}
            type="text"
          >
            <LogoutOutlined />
            <span style={{ marginLeft: 8 }}>Log out</span>
          </Button>

        </div>
      </AntdLayout.Sider>
      <AntdLayout.Content className={styles.content}>
        {children}
      </AntdLayout.Content>
    </AntdLayout>
  );
}
