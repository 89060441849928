import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Search from 'antd/es/input/Search';
import {
  Badge, Button, FormInstance, InputRef, Space,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import clsx from 'clsx';
import SelectFeedType from '../../Common/SelectFeedType';
import {
  addKeyToTableData,
  capitalizeFirstLetter,
  getSorterParams,
  queryFilterParams,
  triggerEnterKeyClick,
} from '../../../utils';
import SelectStatus from '../../Common/SelectStatus';
import Table from '../../Common/Table';
import {
  Product, ProductType, useDeleteProductMutation, useLazyGetProductsQuery,
} from '../../../store/nutrition';
import SelectProductType from '../../Common/SelectProductType';
import { Status } from '../../../types';
import ModalConfirm from '../../Common/ModalConfirm';
import ModalError from '../../Common/ModalError';
import Notification from '../../Common/Notification';

import styles from './index.module.scss';

export default function NutritionTable(): JSX.Element {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchRef = useRef<InputRef>(null);

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('search') || '');
  const [fetchData, { isError: getIsError }] = useLazyGetProductsQuery();
  const [deleteProduct,
    { isError: deleteIsError, isSuccess: deleteIsSuccess, error: deleteError },
  ] = useDeleteProductMutation();

  useEffect(() => {
    setSearchValue(searchParams.get('search') || '');
  }, [searchParams.get('search')]);

  const onSearch = (value: string) => {
    setSearchParams({
      ...searchParams,
      ...queryFilterParams({
        search: value,
      }),
    });

    formRef.current?.submit();
  };

  const toolBarRender = useCallback(() => [
    <Search
      key="search"
      ref={searchRef}
      value={searchValue}
      style={{ width: 278 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
    <Button
      key="button"
      icon={<PlusOutlined />}
      type="primary"
      onClick={() => {
        navigate('/nutrition/create');
      }}
    >
      Add New
    </Button>,
  ], [searchValue, onSearch]);

  useEffect(() => {
    const searchBounce = setTimeout(() => {
      triggerEnterKeyClick(searchRef?.current);
    }, 750);

    return () => clearTimeout(searchBounce);
  }, [searchValue]);

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>,
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Product>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return fetchData(params).then((res) => ({
      data: res?.data?.data ? addKeyToTableData(res.data.data) : [],
      success: res.isSuccess,
      total: res?.data?.meta.totalItems || 0,
    }));
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    let newParamsType = 'system';

    if (beforeSubmitParams.productType !== 'system' && beforeSubmitParams.productType !== undefined) {
      newParamsType = beforeSubmitParams.productType;
    }

    formRef.current?.setFieldsValue({
      productType: newParamsType,
    });

    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
      productType: newParamsType,
    });

    setSearchParams(params);

    return params;
  };

  const columns: ProColumns<Product>[] = [
    {
      title: 'Product List',
      dataIndex: 'productType',
      sorter: false,
      hideInTable: true,
      renderFormItem: () => <SelectProductType />,
    },
    {
      title: 'Name',
      dataIndex: 'nameEn',
      hideInSearch: true,
      sorter: true,
      width: 250,
      className: styles.name,
      renderText: (nameEn, { imageUrl, id, nameNo }) => (
        <Space size={12}>
          {imageUrl ? (
            <img src={process.env.REACT_APP_BUCKET_URL + imageUrl} alt={id} className={styles.image} />)
            : null}
          {!nameEn?.length ? (
            `${nameNo && nameNo?.length >= 50 ? `${nameNo.slice(0, 50)}...` : nameNo || ''}`
          ) : `${nameEn && nameEn?.length >= 50 ? `${nameEn.slice(0, 50)}...` : nameEn || ''}`}
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      hideInSearch: true,
      width: 300,
      className: styles.email,
      hideInTable: searchParams.get('productType') === ProductType.system || !searchParams.get('productType'),
      sorter: false,
      renderText: (_, { user }) => (
        `${user?.email && user.email.length >= 35 ? `${user.email.slice(0, 35)}...` : user?.email || ''}`
      ),
    },
    {
      title: 'Feed type',
      dataIndex: 'feedType',
      sorter: false,
      renderFormItem: () => <SelectFeedType />,
      renderText: (text) => capitalizeFirstLetter(text),
    },
    {
      title: 'Users',
      dataIndex: 'users',
      hideInSearch: true,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
      hideInSearch: true,
      valueEnum: Status,
      renderFormItem: () => <SelectStatus />,
      renderText: (status) => (
        <Badge
          status={status === 'active' ? 'success' : 'default'}
          text={status === 'active' ? 'Active' : 'Inactive'}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      hideInSearch: true,
      width: 120,
      renderText: (_, {
        id, nameEn, status, nameNo, users,
      }) => {
        const isDisabled = !!(status === Status.inactive && users && users > 0);

        return (
          <>
            <Button
              type="text"
              icon={<EditOutlined />}
              className={styles.tableBtn}
              onClick={() => {
                navigate(`/nutrition/${id}`);
              }}
            />
            <Button
              type="text"
              disabled={isDisabled}
              icon={<DeleteOutlined />}
              className={clsx(styles.tableBtn, isDisabled ? styles.disabledBtn : '')}
              onClick={(e) => {
                e.stopPropagation();
                ModalConfirm({
                  onOk: () => deleteProduct(id),
                  title: 'Delete product',
                  content: (
                    <>
                      <div>Are you sure you want to delete product</div>
                      <strong>{nameEn || nameNo}</strong>
                      ?
                    </>
                  ),
                  okText: 'Delete',
                  type: 'Delete',
                });
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (deleteIsSuccess) actionRef?.current?.reload();
  }, [deleteIsSuccess]);

  useEffect(() => {
    if (getIsError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later',
      });
    }
  }, [getIsError]);

  useEffect(() => {
    if (deleteIsError) {
      ModalError({
        title: 'Error',
        content: `${deleteError}`,
      });
    }
  }, [deleteIsError]);

  return (
    <>
      {deleteIsSuccess ? <Notification type="success" message="Product successfully deleted" /> : null}
      <Table<Product>
        formRef={formRef}
        actionRef={actionRef}
        className="dataTable"
        headerTitle="Nutrition product list"
        toolBarRender={toolBarRender}
        showSorterTooltip={false}
        columns={columns}
        request={tableRequest}
        onRow={(record) => ({
          onClick: () => navigate(`/nutrition/${record.id}`),
        })}
        beforeSearchSubmit={beforeSearchSubmit}
      />
    </>
  );
}
