import React, { useRef } from 'react';
import { FormInstance } from 'antd';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { SortOrder } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import Table from '../../../Common/Table';
import { ProductUsers, useLazyGetProductUsersListQuery } from '../../../../store/nutrition';
import { addKeyToTableData, getSorterParams, queryFilterParams } from '../../../../utils';
import ContentTitle from '../../../Common/ContentTitle';

interface ProductUsersTable {
  productId: string,
}

export default function ProductUsersTable({ productId } :ProductUsersTable) {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [fetchData, { data }] = useLazyGetProductUsersListQuery();

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>,
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<ProductUsers>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      productId,
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    return fetchData(params).then((res) => ({
      data: res?.data?.data ? addKeyToTableData(res.data.data) : [],
      success: res.isSuccess,
      total: res?.data?.meta?.totalItems || 0,
    }));
  };

  const columns: ProColumns<ProductUsers>[] = [
    {
      title: 'User',
      dataIndex: 'name',
      sorter: false,
      renderText: (text, { id }) => (
        <Link to={`/users/${id}`}>
          {`${text.length >= 50 ? `${text.slice(0, 50)}...` : text}`}
        </Link>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      sorter: true,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      renderText: (country) => country?.name,
    },
  ];

  return (
    <>
      <ContentTitle title={`Users who use this product (${data?.meta?.totalItems || 0})`} divider />
      <Table
        search={false}
        formRef={formRef}
        toolBarRender={false}
        actionRef={actionRef}
        showSorterTooltip={false}
        columns={columns}
        request={tableRequest}
      />
    </>
  );
}
