import React from 'react';
import config from '../../store/auth';
import EmailAndPassword from '../../components/Pages/SignIn';
import Content from '../../components/Common/Wrapper/Content';
import AuthLayout from '../../components/Layout/Auth';

const { displayName } = config;

export default function SignInPage(): JSX.Element {
  document.title = `${displayName}: Sign in`;

  return (
    <AuthLayout>
      <Content>
        <EmailAndPassword />
      </Content>
    </AuthLayout>
  );
}
