import React, { useState } from 'react';
import { Button, Form as AntdForm, Input } from 'antd';
import { NavLink } from 'react-router-dom';
import { createRulesForAntd, validationForgotPassword } from '../../../../utils/validations';
import { validateTrigger } from '../../../../types';

const validationRules = createRulesForAntd(validationForgotPassword);

interface ForgotPasswordForm {
  onFinish: (data: {email: string}) => void,
  isLoading: boolean,
  isError: boolean,
}

function ForgotPasswordForm({ onFinish, isLoading }: ForgotPasswordForm) {
  const [form] = AntdForm.useForm();
  const [isFinishFailed, setIsFinishFailed] = useState<boolean>(false);
  const [validationType, setValidationType] = useState({
    email: { validateTrigger: validateTrigger.onBlur },
  });

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValidationType({
      ...validationType,
      [e.target.name]: { validateTrigger: validateTrigger.onChange },
    });
  };

  const handleOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore ignore this line
    if (isFinishFailed && validationType[e.target.name].validateTrigger === validateTrigger.onBlur) {
      form.setFields([{ name: e.target.name, errors: [] }]);
    }
  };

  return (
    <AntdForm
      form={form}
      className="authForm"
      name="forgotPassword"
      autoComplete="off"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={() => setIsFinishFailed(true)}
    >
      <AntdForm.Item
        name="email"
        className="item"
        rules={[validationRules]}
        validateTrigger={validationType.email.validateTrigger}
      >
        <Input
          name="email"
          placeholder="Email"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </AntdForm.Item>
      <AntdForm.Item
        className="submit"
      >
        <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%' }}>
          Send password reset link
        </Button>
      </AntdForm.Item>

      <AntdForm.Item className="backToLogin">
        Back to
        <NavLink to="/login" style={{ marginLeft: 5 }}>Login</NavLink>
      </AntdForm.Item>
    </AntdForm>
  );
}

export default ForgotPasswordForm;
