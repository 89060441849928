import React, { useEffect, useRef, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { FormInstance } from 'antd';
import { useNavigate } from 'react-router-dom';
import DetailPageToolBar from '../../../Common/DetailPageToolBar';
import ImageUploader from '../../../Common/UploadImage';
import ContentTitle from '../../../Common/ContentTitle';
import NutritionDetailForm from '../Form/inde';
import { Product, ProductType, useCreateProductMutation } from '../../../../store/nutrition';
import Loading from '../../../Common/Loading';
import ModalError from '../../../Common/ModalError';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { updateUnsavedChanges } from '../../../../store/unsavedChanges';
import Notification from '../../../Common/Notification';
import { calculatedValuesByDM } from '../Edit';

export default function NutritionCreate() {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState<string>('');
  const formRef = useRef<FormInstance>(null);
  const [createProduct,
    { isLoading, isError, isSuccess },
  ] = useCreateProductMutation();

  const handleSave = () => {
    formRef.current?.submit();
  };

  const handleImage = (value: string) => {
    setImageUrl(value);
    dispatch(updateUnsavedChanges(true));
  };

  const handleFinish = (formData: Product) => {
    // eslint-disable-next-line prefer-const
    let { productType, ...newFormData } = formData;

    if (formData.productType !== ProductType.system && user) {
      newFormData = { ...newFormData, user: { id: user.id, email: user.email } };
    }

    if (imageUrl) {
      newFormData = { ...newFormData, imageUrl };
    }

    createProduct(newFormData);
    dispatch(updateUnsavedChanges(false));
  };

  useEffect(() => {
    if (isError) {
      ModalError({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) navigate(-1);
  }, [isSuccess]);

  return (
    <>
      <Loading visible={isLoading} />
      {
        isSuccess ? <Notification type="success" message="Nutrition product successfully added" /> : null
      }
      <DetailPageToolBar
        title="Add nutrition product"
        handleSave={handleSave}
        deleteText="product"
        deleteTitleText="product"
      />
      <Content className="content-wrap">
        <ContentTitle title="Add nutrition product" style={{ padding: '0 0 16px' }} />
        <ImageUploader handleImage={handleImage} />
        <ContentTitle title="General product information" divider />
        <NutritionDetailForm handleFinish={handleFinish} formRef={formRef} />
      </Content>
    </>
  );
}
