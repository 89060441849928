import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { connect } from 'react-redux';
import { signIn as signInAction, SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { Action } from '@triare/auth-redux';

import { useNavigate } from 'react-router-dom';
import MainLogo from '../../Common/MainLogo';
import SignInForm from './Form';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import Notification from '../../Common/Notification';
import { clearError } from '../../../store/auth';

interface EmailAndPasswordProps {
  signIn: (payload: SignInAction) => Action;
}

function EmailAndPassword({ signIn }: EmailAndPasswordProps): JSX.Element {
  const {
    authorized,
    userMe,
    user,
    signIn: stateSignIn,
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userMe.loading && user && authorized) {
      navigate('/dashboard');
    }
  }, [userMe.loading, authorized]);

  useEffect(() => {
    dispatch(clearError(null));
  }, []);

  return (
    <Row justify="center">
      <Col>
        <div className="authFormWrapper">
          <MainLogo styles={{ marginBottom: '10px' }} />
          <Typography.Title level={4} className="title">
            Welcome to equip
          </Typography.Title>
          <SignInForm
            handleSignIn={(data: SignInAction) => signIn({ ...data, remember: true })}
            isLoading={userMe?.loading}
          />
          {
            stateSignIn.error?.data?.message ? (
              <Notification type="error" message={stateSignIn.error.data.message} />
            ) : null
          }
          {
            userMe?.error?.message ? (
              <Notification type="error" message={userMe?.error?.message} />
            ) : null
          }
        </div>
      </Col>
    </Row>
  );
}

export default connect(null, {
  signIn: signInAction,
})(EmailAndPassword);
