import React from 'react';
import config from '../../../store/auth';
import Layout from '../../../components/Layout';
import NutritionCreate from '../../../components/Pages/Nutrition/Create';

const { displayName } = config;

export default function NutritionCreatePage(): JSX.Element {
  document.title = `${displayName}: Nutrition Create`;

  return (
    <Layout>
      <NutritionCreate />
    </Layout>
  );
}
