import React, { useEffect, useRef, useState } from 'react';
import { FormInstance, InputRef } from 'antd';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { SortOrder } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import Table from '../../../Common/Table';
import {
  addKeyToTableData, getSorterParams, queryFilterParams, triggerEnterKeyClick,
} from '../../../../utils';
import FeedTypeFilters from './Filters';
import ContentTitle from '../../../Common/ContentTitle';
import { useLazyProductsByTypeQuery } from '../../../../store/dashboard';
import { Product, useLazyGetProductsQuery } from '../../../../store/nutrition';
import { FeedType } from '../../../../types';

import styles from './index.module.scss';

export default function UsersByNutrition() {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const searchRef = useRef<InputRef>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentFilter, setCurrentFilter] = useState<string>(FeedType.forage);

  const [fetchData] = useLazyGetProductsQuery();
  const [fetchProductsByType, { data: filtersData }] = useLazyProductsByTypeQuery();

  useEffect(() => {
    fetchProductsByType(null);
  }, []);

  const onSearch = () => {
    formRef.current?.submit();
  };

  useEffect(() => {
    const searchBounce = setTimeout(() => {
      triggerEnterKeyClick(searchRef?.current);
    }, 750);

    return () => clearTimeout(searchBounce);
  }, [searchValue]);

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>,
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<Product>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      search: searchValue,
      feedType: currentFilter,
      ...args,
      ...getSorterParams(sorter),
    });

    return fetchData(params).then((res) => ({
      data: res?.data?.data ? addKeyToTableData(res.data.data) : [],
      success: res.isSuccess,
      total: res?.data?.meta.totalItems || 0,
    }));
  };

  const columns: ProColumns<Product>[] = [
    {
      title: 'Product',
      dataIndex: 'nameEn',
      sorter: false,
      renderText: (text, { id, nameNo }) => (
        <Link to={`/nutrition/${id}`}>{text || nameNo || ''}</Link>
      ),
    },
    {
      title: 'Users',
      dataIndex: 'users',
      sorter: true,
    },
  ];

  const handleSetFilter = (value: string) => {
    setCurrentFilter(value);
    actionRef.current?.reload();
  };

  return (
    <div className={styles.wrapper}>
      <ContentTitle title="Users by Nutrition products" style={{ paddingBottom: 20 }} />
      <Search
        ref={searchRef}
        value={searchValue}
        style={{ width: 278 }}
        onSearch={onSearch}
        onChange={(value) => setSearchValue(value.target.value)}
        placeholder="Search"
      />
      <div className={styles.tableWrap}>
        <FeedTypeFilters
          filters={filtersData || []}
          handleSetFilter={handleSetFilter}
          currentFilter={currentFilter}
        />
        <Table
          className="customToolbar paddingLeft-12 hide-native-search"
          syncToUrl={false}
          formRef={formRef}
          options={{ reload: false, setting: false }}
          actionRef={actionRef}
          showSorterTooltip={false}
          columns={columns}
          request={tableRequest}
        />
      </div>
    </div>
  );
}
