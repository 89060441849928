import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../index';
import { selectToken } from '../../utils';
import { PagingDataResponse } from '../../types';

export interface DashboardParams {
  fromDate?: string,
  tillDate?: string
}

export interface UserActivity {
  days: string,
  period: string,
  value: number
}

export interface Retention {
  day: string,
  value: number
}

export interface UsersActivities{
  name: string,
  views: number,
  users: number,
  viewsPerUser: number,
  avgTime: number,
}

export interface ProductsByType {
  name: string,
  count: number,
}

export interface UsersByLocation {
  id: string,
  city: string,
  country: string,
  users: number
}

export interface UsersActivitiesGetParams {
  page?: string[],
  pageSize?: string[],
  search?: string[],
  orderBy?: string[],
  orderByColumn?: string[],

}

export interface UsersByLocationGetParams {
  page?: string[],
  pageSize?: string[],
  search?: string[],
  orderBy?: string[],
  orderByColumn?: string[],
}

export type UsersActivitiesParams = UsersActivitiesGetParams & DashboardParams
export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      const token = selectToken(getState() as RootState);

      if (token) {
        headers.set('Authorization', `Bearer ${token.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    userActivity: builder.query<UserActivity[], Required<DashboardParams>>({
      query: (params) => ({
        url: '/admin/dashboard/user-activity',
        method: 'GET',
        params,
      }),
    }),
    retention: builder.mutation<Retention[], Required<DashboardParams>>({
      query: (params) => ({
        url: '/admin/dashboard/retention',
        method: 'GET',
        params,
      }),
    }),
    usersActivities: builder.query<PagingDataResponse<UsersActivities>, UsersActivitiesParams>({
      query: (params) => ({
        url: '/admin/dashboard/user-screens',
        method: 'GET',
        params,
      }),
    }),
    productsByType: builder.query<ProductsByType[], null>({
      query: () => ({
        url: '/admin/dashboard/products-by-type',
        method: 'GET',
      }),
    }),
    usersByLocation: builder.query<PagingDataResponse<UsersByLocation>, UsersByLocationGetParams>({
      query: (params) => ({
        url: '/admin/dashboard/user-cities',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useLazyUserActivityQuery,
  useRetentionMutation,
  useLazyUsersActivitiesQuery,
  useLazyProductsByTypeQuery,
  useLazyUsersByLocationQuery,
} = dashboardApi;
