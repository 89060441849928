import React, { useEffect, useRef, useState } from 'react';
import { FormInstance, InputRef } from 'antd';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { SortOrder } from 'antd/es/table/interface';
import Search from 'antd/es/input/Search';
import Table from '../../../Common/Table';
import {
  addKeyToTableData, getSorterParams, queryFilterParams, triggerEnterKeyClick,
} from '../../../../utils';
import { useLazyUsersByLocationQuery } from '../../../../store/dashboard';

interface UsersLocation {
  id?: string,
  city?: string,
  country?: string,
  users?: string | number,
}

export default function UsersByLocation() {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const searchRef = useRef<InputRef>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [fetchData] = useLazyUsersByLocationQuery();

  const toolBarRender = () => ([
    <Search
      key="search"
      ref={searchRef}
      value={searchValue}
      style={{ width: 278 }}
      onSearch={onSearch}
      onChange={(value) => setSearchValue(value.target.value)}
      placeholder="Search"
    />,
  ]);

  const onSearch = () => {
    formRef.current?.submit();
  };

  useEffect(() => {
    const searchBounce = setTimeout(() => {
      triggerEnterKeyClick(searchRef?.current);
    }, 750);

    return () => clearTimeout(searchBounce);
  }, [searchValue]);

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>,
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<UsersLocation>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      search: searchValue,
      ...args,
      ...getSorterParams(sorter),
    });

    return fetchData(params).then((res) => ({
      data: res?.data?.data ? addKeyToTableData(res.data.data) : [],
      success: res.isSuccess,
      total: res?.data?.meta.totalItems || 0,
    }));
  };

  const columns: ProColumns<UsersLocation>[] = [
    {
      title: 'City',
      dataIndex: 'city',
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'Users',
      dataIndex: 'users',
      sorter: true,
    },
  ];

  return (
    <Table
      className="customToolbar hide-native-search"
      syncToUrl={false}
      formRef={formRef}
      columns={columns}
      actionRef={actionRef}
      toolbar={{
        title: 'Users by Location',
        settings: undefined,
      }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      request={tableRequest}
    />
  );
}
