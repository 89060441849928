import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Logo } from '../Icon';
import ModalConfirm from '../ModalConfirm';
import { updateUnsavedChanges } from '../../../store/unsavedChanges';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

import styles from './index.module.scss';

interface MainLogo {
  styles?: { [key: string]: string };
}

function MainLogo({ styles: additionalStyles }: MainLogo) {
  const dispatch = useAppDispatch();
  const { unsavedChanges } = useAppSelector((state) => state.unsavedChanges);
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (unsavedChanges) {
      e.preventDefault();

      ModalConfirm({
        title: 'Unsaved changes',
        content: 'You have unsaved changes. Discard changes and leave?',
        okText: 'Discard',
        type: 'Discard',
        onOk: () => {
          dispatch(updateUnsavedChanges(false));
          navigate('/');
        },
      });
    }
  };

  return (
    <div className={clsx(styles.mainLogoWrap)} style={additionalStyles}>
      <Link to="/" onClick={(e) => handleClick(e)}><Logo /></Link>
    </div>
  );
}

MainLogo.defaultProps = {
  styles: {},
};

export default MainLogo;
